import React from 'react';
import {Box, Stack} from '@mui/system';
import { Divider, Link, styled, Typography, useTheme } from '@mui/material';
import {paletteMains} from '../../../lib/utils';
import Grid from '@mui/material/Unstable_Grid2';
import logo from '../../../sass/images/footer/University_Wordmark_Reversed.png';
import { Facebook, Instagram, Pinterest, X, YouTube } from '@mui/icons-material';

const Footer = () => {
  const theme = useTheme();
  const palette = paletteMains(theme);

  const Logo = styled('a')({
    background: `url(${logo}) no-repeat`,
    backgroundSize: 'cover',
    width: '235px',
    height: '61px',
  });

  const iconFontSize = '32px';

  return (
    <>
      <Box id="footer" sx={{
        color: '#fff',
        marginTop: '100px',
        background: palette.ilblue,
        padding: '32px 0 24px',
      }}>
        <Grid container className="footer-layout" rowSpacing={2}>
          <Grid xsOffset={1} xs={10} className="footer-content">
            <Stack spacing={3} className="footer-primary-block">
              <Logo href="https://illinois.edu/" />
              <Grid container justifyContent="space-between" className="footer-primary-container">
                <Grid xs={'auto'} className="footer-primary-left">
                  <Typography variant="h6">
                    <Link variant="footer" href="https://eat-move-save.extension.illinois.edu/">
                      Eat.Move.Save
                    </Link>
                  </Typography>
                  <Typography variant="body1">
                    University of Illinois Extension
                    <br/>
                    Email: <Link variant="footer" href="mailto:eatmovesave@illinois.edu">eatmovesave@illinois.edu</Link>
                  </Typography>
                  <Stack direction="row" spacing={3} mt="8px" mb={{ xs: '24px', md: 0 }}>
                    <Link href="https://www.instagram.com/eatmovesave/" color="#fff" fontSize={iconFontSize}>
                      <Instagram fontSize="inherit"/>
                    </Link>
                    <Link href="https://www.facebook.com/IllinoisNEP" color="#fff" fontSize={iconFontSize}>
                      <Facebook fontSize="inherit" />
                    </Link>
                    <Link href="https://twitter.com/IllinoisNEP" color="#fff" fontSize={iconFontSize}>
                      <X fontSize="inherit" />
                    </Link>
                    <Link href="https://www.youtube.com/@eatmovesave" color="#fff" fontSize={iconFontSize}>
                      <YouTube fontSize="inherit" />
                    </Link>
                    <Link href="https://www.pinterest.com/eatmovesave" color="#fff" fontSize={iconFontSize}>
                      <Pinterest fontSize="inherit" />
                    </Link>
                  </Stack>
                </Grid>
                <Grid xs={12} md={4} className="footer-primary-right" mb="16px">
                  <Typography>
                    This institution is an equal opportunity provider. This material was funded by USDA&apos;s Supplemental Nutrition Assistance Program - SNAP. &nbsp; <Link variant="footer" href="https://eat-move-save.extension.illinois.edu/about/program-statement">Full Program Statement</Link>
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid xs={12} pt={0}>
            <Divider sx={{ borderColor: palette.ilalma, borderWidth: '3px' }}/>
          </Grid>
          <Grid xs={10} xsOffset={1} className="footer-secondary">
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 8 }}>
              <Link href="https://www.vpaa.uillinois.edu/resources/web_privacy" variant="footer">Privacy Policy</Link>
              <Link href="https://illinois.edu/resources/website/copyright.html" variant="footer">© 2024 University of Illinois Board of Trustees</Link>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
