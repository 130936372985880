import { Button, CircularProgress, FormGroup, FormLabel, Link, List, ListItem, Typography } from '@mui/material';
import { AutocompleteElement, FormContainer, RadioButtonGroup, TextFieldElement } from 'react-hook-form-mui';
import CheckboxButtonGroup from '../../common/HookFormMui/CheckboxButtonGroup';
import { participantNeeds, programAreas } from '../../../lib/constants';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import { toOption } from '../../../lib/utils';
import UserContext from '../../../lib/contexts/userContext';
import { AbilityContext, Subjects } from '../../../lib/permissions';
import useErrorHandling from '../../common/hooks/useErrorHandling';

const EventForm = ({ form, event, pearsData, staffUsers, availableCurriculum, submitting, onSubmit, valuesSetState }) => {
  const { userState: { userRecord  } } = useContext(UserContext);

  const [additionalOptions, setAdditionalOptions] = useState([]);

  const [wasSubmitting, setWasSubmitting] = useState(false);
  const [valuesSet, setValuesSet] = valuesSetState;

  const ability = useContext(AbilityContext);
  const userCanAssign = ability.can('assign', Subjects.EVENT);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = form;

  const eventTypeWatch = watch('event_type');
  const areaWatch = watch('program_areas');
  const curriculumWatch = watch('curriculum');

  const toUserOption = ({ email, name }) => ({
    id: email,
    label: name ? `${name} (${email})` : email,
  });

  const updateAdditionalOptions = useCallback((selectedCurriculum, selectedAdditional) => {
    const curriculum = selectedCurriculum ? availableCurriculum.find((c) => c.id === selectedCurriculum.id) : null;
    if (curriculum && curriculum.additional.length) {
      const additionalOpts = curriculum.additional.map((add) => ({
        id: add.id,
        label: add.additional_name,
      }));
      additionalOpts.unshift({ id: '', label: 'None' });
      setAdditionalOptions(additionalOpts);

      if (selectedAdditional && !curriculum.additional.find((add) => add.id === selectedAdditional.id)) {
        if (additionalOpts.length) {
          // There are additional options, but the user has selected 'None'
          setValue('additional', { id: '', label: 'None' })
        } else {
          setValue('additional', null);
        }
      }
    } else {
      setAdditionalOptions([]);
      setValue('additional', null);
    }
  }, [availableCurriculum, setValue]);

  /** ERROR HANDLING **/
  useErrorHandling({ errors, isSubmitting, wasSubmitting, setWasSubmitting });

  const curriculumOptions = useMemo(() => {
    const areas = areaWatch || [];

    // Apply filter to curricula based on program area(s)
    const areaFilter = (cur) => {
      return areas.reduce((acc, area) => acc || cur.program_areas?.includes(area), false);
    };
    // Curate list of curriculum names for select
    return availableCurriculum
      .filter(areaFilter)
      .map((item) => ({
        id: item.id,
        label: item.curriculum_name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [areaWatch, availableCurriculum]);

  useEffect(() => {
    if (!valuesSet) {
      return;
    }
    // Clear selected curriculum (and additional) if it's not in the list
    if (!curriculumWatch || !curriculumOptions.find((c) => c.id === curriculumWatch.id)) {
      setValue('curriculum', null);
      setValue('additional', null);
      setAdditionalOptions([]);
    }
  }, [curriculumOptions, curriculumWatch, setValue, valuesSet]);

  useEffect(() => {
    if (!(areaWatch || []).some((area) => ['snap_ed', 'cphp'].includes(area))) {
      setValue('special_project', null);
    }
  }, [areaWatch, setValue]);

  useEffect(() => {
    const getUser = (email) => staffUsers.find((u) => u.email === email);
    if (event === false) {
      // No event means we are creating one
      if (!valuesSet) {
        setValue('created_by', toUserOption(getUser(userRecord?.email)));
        setValuesSet(true);
      }
      return;
    } else if (!event) {
      // Event was not found
      return;
    }
    const values = {
      points: event.points,
      created_by: toUserOption(getUser(event.created_by)),
      event_name: event.event_name,
      event_partner: event.event_partner,
      main_office: event.main_office,
      special_project: event.special_project,
      internal_only: event.internal_only ? 'true' : 'false',
      event_type: event.event_type,
      additional: event.additionalID
        ? toOption(event.additionalID, event.additional.additional_name)
        : toOption('', 'None'),
      curriculum: event.curriculumID ? toOption(event.curriculumID, event.curriculum?.curriculum_name) : null,
      participant_needs: event.participant_needs,
      participant_needs_other: event.participant_needs_other,
      program_areas: event.program_areas,
    };
    Object.keys(values).forEach((key) => setValue(key, values[key]));
    updateAdditionalOptions(values.curriculum, values.additional);
    setValuesSet(true);
  }, [event, setValue, setValuesSet, staffUsers, updateAdditionalOptions, userRecord?.email, valuesSet]);

  const handleEventTypeChange = async (value) => {
    if (value === 'BOOTH') {
      setValue('curriculum', '');
      setValue('additional', '');
      setValue('participant_needs', []);
    }
  };

  const updateEventName = () => {
    if (!valuesSet) {
      return;
    }
    const eventName = getValues('event_name');
    if (eventName) {
      return null;
    }

    let curriculum = getValues('curriculum');
    curriculum = curriculum?.label || curriculum;
    let partner = getValues('event_partner');
    partner = partner?.label || partner;
    const args = [];
    if (curriculum) {
      args.push(curriculum.trim());
    }
    if (partner) {
      args.push(partner.trim());
    }
    if (args.length === 2) {
      setValue('event_name', args.join(' - '));
    }
  };

  const handleCurriculumChange = (_event, value) => {
    const selectedAdditional = getValues('additional');
    updateEventName();
    updateAdditionalOptions(value, selectedAdditional);
  };

  const handlePartnerChange = () => {
    if (!valuesSet) {
      return;
    }
    updateEventName();
  };


  const getFormField = (field) => (
    <ListItem sx={{ display: "list-item" }} key={`field-${field.props.id}`}>
      { field }
    </ListItem>
  );

  /**
   * Displays a loader until pearsData is not null.
   */
  const PearsElement = ({ id, name, children }) => (
    (pearsData === null ?
        <FormGroup
          id={id}
          name={name}
        >
          <FormLabel>{children.props.label}</FormLabel>
          <CircularProgress size="1.5rem" />
        </FormGroup>
        :
        <>{ children }</>
    )
  );

  const fields = [];

  fields.push(
    <RadioButtonGroup
      id="field-internal_only"
      name="internal_only"
      label={'What is this event being created for?'}
      placeholder={'Select event purpose'}
      variant={'standard'}
      control={control}
      fullWidth
      required
      options={[
        { label: 'Community Event', id: 'false' },
        { label: 'Training/Testing Event', id: 'true' },
      ]}
    />
  );

  fields.push(
    <CheckboxButtonGroup
      label="Which program area(s) is delivering this event?"
      id="field-program_areas"
      name="program_areas"
      control={control}
      required
      fullWidth
      options={programAreas}
    />
  );

  fields.push(
    <RadioButtonGroup
      id="field-event_type"
      name="event_type"
      label={'What type of event is this?'}
      placeholder={'Select event type'}
      variant={'standard'}
      control={control}
      onChange={handleEventTypeChange}
      fullWidth
      required
      options={[
        { label: 'Direct Education Class(es)', id: 'CLASS' },
        { label: 'Booth', id: 'BOOTH' },
      ]}
    />
  );

  if (eventTypeWatch === 'CLASS') {
    fields.push(
      <AutocompleteElement
        id="field-curriculum"
        name="curriculum"
        label={'What is the primary curriculum for this event?'}
        placeholder={'Choose curriculum'}
        textFieldProps={{ variant: 'standard' }}
        control={control}
        fullWidth
        required
        options={curriculumOptions || []}
        autocompleteProps={{
          onChange: handleCurriculumChange,
          isOptionEqualToValue: (option, newValue) => {
            return option?.id === newValue?.id;
          }
      }}
      />
    );
    if (additionalOptions && additionalOptions.length > 0) {
      fields.push(
        <AutocompleteElement
          id="field-additional"
          name="additional"
          label={'What is the additional curriculum for this event?'}
          placeholder={'Choose additional curriculum'}
          textFieldProps={{variant: 'standard'}}
          control={control}
          fullWidth
          required
          options={additionalOptions}
          autocompleteProps={{
            isOptionEqualToValue: (option, newValue) => {
              return option?.id === newValue?.id;
            }
          }}
        />
      );
    }
  }

  /**
   * PEARS field
   */
  fields.push(
    <PearsElement
      id="field-event_partner"
      name="event_partner"
    >
      <AutocompleteElement
        id="field-event_partner"
        name="event_partner"
        label={'Which partner are you coordinating this event with?'}
        textFieldProps={{
          variant: 'standard',
          helperText: <>If you cannot find a site, please email <Link href="mailto:uie-inepdocs@illinois.edu">uie-inepdocs@illinois.edu</Link> to request to add the site.</>
        }}
        control={control}
        fullWidth
        required
        options={pearsData?.partners || []}
        autocompleteProps={{
          onChange: handlePartnerChange,
          isOptionEqualToValue: (option, newValue) => {
            return option === newValue;
          }
        }}
      />
    </PearsElement>
  );

  if (eventTypeWatch === 'CLASS') {
    fields.push(
      <CheckboxButtonGroup
        label="What are the specific needs of this group that you will consider while planning this event? Select all that apply."
        id="field-participant_needs"
        name="participant_needs"
        control={control}
        required
        fullWidth
        textFieldRequired
        options={participantNeeds}
      />
    );
  }

  /**
   * PEARS field
   */
  fields.push(
    <PearsElement
      id="field-main_office"
      name="main_office"
    >
      <AutocompleteElement
        id="field-main_office"
        name="main_office"
        label={'What is your main office?'}
        placeholder={'Choose main office'}
        textFieldProps={{variant: 'standard'}}
        control={control}
        fullWidth
        required
        options={pearsData?.main_offices || []}
        autocompleteProps={{
          isOptionEqualToValue: (option, newValue) => {
            return option === newValue;
          }
        }}
      />
    </PearsElement>
  );

  /**
   * myINEP field
   */
  fields.push(
    <AutocompleteElement
      id="field-created_by"
      name="created_by"
      label={'Created By'}
      helperText={'Name and email address of the user assigned to this event.'}
      placeholder={'Choose assignee'}
      textFieldProps={{variant: 'standard'}}
      control={control}
      fullWidth
      required
      options={(staffUsers ? staffUsers.map(toUserOption) : [])}
      autocompleteProps={{
        readOnly: !userCanAssign,
        isOptionEqualToValue: (option, newValue) => {
          return option?.id === newValue?.id;
        }
      }}
    />
  );

  /**
   * Autogenerated at first
   */
  fields.push(
    <TextFieldElement
      id="field-event_name"
      name="event_name"
      label={'Event Name'}
      variant={'standard'}
      control={control}
      required
      fullWidth
    />
  );

  /**
   * PEARS field
   */
  if ((areaWatch || []).some((area) => ['snap_ed', 'cphp'].includes(area))) {
    fields.push(
      <PearsElement
        id="field-special_project"
        name="special_project"
      >
        <AutocompleteElement
          id="field-special_project"
          name="special_project"
          label={'Which SNAP-Ed Special Project is this event part of?'}
          placeholder={''}
          textFieldProps={{ variant: 'standard' }}
          control={control}
          fullWidth
          required
          options={pearsData?.special_projects || []}
          autocompleteProps={{
            isOptionEqualToValue: (option, newValue) => {
              return option === newValue;
            }
          }}
        />
      </PearsElement>
    );
  }

  return (
    <Box width={'100%'}>
      { !!valuesSet && (
        <FormContainer onSuccess={handleSubmit(onSubmit)} FormProps={{ className: 'mui' }}>
          <Typography mt={'15px'} mb={'15px'} sx={{ fontWeight: 600 }} component="div">
            <label className={'form-text required'}>Required Fields</label>
          </Typography>
          <Grid container columns={12} spacing={2}>
            <Grid xs={12} sm={10} md={8}>
              <List sx={{
                  listStyle: 'decimal',
                  paddingLeft: 2,
                }}>
                { fields.map(getFormField) }
              </List>
            </Grid>

            <Grid xs={12} sm={10} md={8}>
              <Button
                id="button-submit"
                disabled={submitting}
                type={'submit'}
                variant="contained"
                size="small"
                sx={{ marginTop: '20px' }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </FormContainer>
      )}
    </Box>
  );
};

export default EventForm;
