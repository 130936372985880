import { Pages } from '../../../../lib/constants';
import Page from '../../Page';
import ViewEvents from './ViewEvents';
import { useState } from 'react';

export const ViewEventsPage = () => {
  const [title, setTitle] = useState('Events');
  return (
    <Page
      title={title}
      sectionName="events"
      pageId={Pages.EVENTS}
      requireAuth
      breadcrumbs={['events']}
    >
      <ViewEvents setTitle={setTitle} />
    </Page>
  )
};

export default ViewEventsPage;
