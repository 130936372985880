/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCurriculum = /* GraphQL */ `
  query GetCurriculum($id: ID!) {
    getCurriculum(id: $id) {
      id
      curriculum_name
      additional {
        items {
          id
          curriculumID
          curriculumAdditionalID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lessons {
        items {
          id
          curriculumID
          lesson_name
          booth_topic
          snap_ed
          efnep
          cphp
          points
          description
          display_order
          audience
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          event_name
          event_type
          event_partner
          internal_only
          program_areas
          curriculumID
          additionalID
          participant_needs
          participant_needs_other
          main_office
          special_project
          points
          created_by
          updated_by
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCurricula = /* GraphQL */ `
  query ListCurricula(
    $filter: ModelCurriculumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurricula(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        curriculum_name
        additional {
          nextToken
          __typename
        }
        lessons {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCurriculumLesson = /* GraphQL */ `
  query GetCurriculumLesson($id: ID!) {
    getCurriculumLesson(id: $id) {
      id
      curriculumID
      curriculum {
        id
        curriculum_name
        additional {
          nextToken
          __typename
        }
        lessons {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      lesson_name
      booth_topic
      snap_ed
      efnep
      cphp
      points
      description
      display_order
      audience
      sessions {
        items {
          id
          session_name
          status
          eventID
          lessonID
          start
          end
          delivery_method
          delivery_url
          delivery_language
          material_language
          delivery_location
          other_details
          has_wifi
          demonstration
          recipes
          short_code
          qr_code
          participant_count
          cancel_reason
          canceled_by
          created_by
          updated_by
          canceledAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCurriculumLessons = /* GraphQL */ `
  query ListCurriculumLessons(
    $filter: ModelCurriculumLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurriculumLessons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        curriculumID
        curriculum {
          id
          curriculum_name
          createdAt
          updatedAt
          owner
          __typename
        }
        lesson_name
        booth_topic
        snap_ed
        efnep
        cphp
        points
        description
        display_order
        audience
        sessions {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCurriculumAdditional = /* GraphQL */ `
  query GetCurriculumAdditional($id: ID!) {
    getCurriculumAdditional(id: $id) {
      id
      additional_name
      snap_ed
      efnep
      cphp
      points
      description
      audience
      display_order
      curricula {
        items {
          id
          curriculumID
          curriculumAdditionalID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          event_name
          event_type
          event_partner
          internal_only
          program_areas
          curriculumID
          additionalID
          participant_needs
          participant_needs_other
          main_office
          special_project
          points
          created_by
          updated_by
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCurriculumAdditionals = /* GraphQL */ `
  query ListCurriculumAdditionals(
    $filter: ModelCurriculumAdditionalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurriculumAdditionals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        additional_name
        snap_ed
        efnep
        cphp
        points
        description
        audience
        display_order
        curricula {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      title
      description
      points
      activity_code
      users {
        items {
          id
          activityID
          userID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listActivities = /* GraphQL */ `
  query ListActivities(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        points
        activity_code
        users {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      event_name
      event_type
      event_partner
      internal_only
      program_areas
      curriculumID
      curriculum {
        id
        curriculum_name
        additional {
          nextToken
          __typename
        }
        lessons {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      additionalID
      additional {
        id
        additional_name
        snap_ed
        efnep
        cphp
        points
        description
        audience
        display_order
        curricula {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      participant_needs
      participant_needs_other
      main_office
      special_project
      points
      sessions {
        items {
          id
          session_name
          status
          eventID
          lessonID
          start
          end
          delivery_method
          delivery_url
          delivery_language
          material_language
          delivery_location
          other_details
          has_wifi
          demonstration
          recipes
          short_code
          qr_code
          participant_count
          cancel_reason
          canceled_by
          created_by
          updated_by
          canceledAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      created_by
      updated_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event_name
        event_type
        event_partner
        internal_only
        program_areas
        curriculumID
        curriculum {
          id
          curriculum_name
          createdAt
          updatedAt
          owner
          __typename
        }
        additionalID
        additional {
          id
          additional_name
          snap_ed
          efnep
          cphp
          points
          description
          audience
          display_order
          createdAt
          updatedAt
          owner
          __typename
        }
        participant_needs
        participant_needs_other
        main_office
        special_project
        points
        sessions {
          nextToken
          __typename
        }
        created_by
        updated_by
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      session_name
      status
      eventID
      event {
        id
        event_name
        event_type
        event_partner
        internal_only
        program_areas
        curriculumID
        curriculum {
          id
          curriculum_name
          createdAt
          updatedAt
          owner
          __typename
        }
        additionalID
        additional {
          id
          additional_name
          snap_ed
          efnep
          cphp
          points
          description
          audience
          display_order
          createdAt
          updatedAt
          owner
          __typename
        }
        participant_needs
        participant_needs_other
        main_office
        special_project
        points
        sessions {
          nextToken
          __typename
        }
        created_by
        updated_by
        createdAt
        updatedAt
        __typename
      }
      lessonID
      lesson {
        id
        curriculumID
        curriculum {
          id
          curriculum_name
          createdAt
          updatedAt
          owner
          __typename
        }
        lesson_name
        booth_topic
        snap_ed
        efnep
        cphp
        points
        description
        display_order
        audience
        sessions {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      start
      end
      delivery_method
      delivery_url
      delivery_language
      material_language
      delivery_location
      other_details
      has_wifi
      demonstration
      recipes
      short_code
      qr_code
      participant_count
      cancel_reason
      canceled_by
      created_by
      updated_by
      canceledAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        session_name
        status
        eventID
        event {
          id
          event_name
          event_type
          event_partner
          internal_only
          program_areas
          curriculumID
          additionalID
          participant_needs
          participant_needs_other
          main_office
          special_project
          points
          created_by
          updated_by
          createdAt
          updatedAt
          __typename
        }
        lessonID
        lesson {
          id
          curriculumID
          lesson_name
          booth_topic
          snap_ed
          efnep
          cphp
          points
          description
          display_order
          audience
          createdAt
          updatedAt
          owner
          __typename
        }
        start
        end
        delivery_method
        delivery_url
        delivery_language
        material_language
        delivery_location
        other_details
        has_wifi
        demonstration
        recipes
        short_code
        qr_code
        participant_count
        cancel_reason
        canceled_by
        created_by
        updated_by
        canceledAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userpool_user_id
      email
      phone_number
      street_address
      city
      county
      race
      ethnicity
      gender
      age_range
      zip_code
      household_size
      allow_cellphone
      allow_notification
      allow_enews
      allow_text_program
      is_participating
      is_eligible
      first_name
      last_name
      full_name
      state
      birth_year
      is_sso
      points {
        items {
          id
          points
          type
          userID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          activityID
          userID
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        is_sso
        points {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserByUserpoolUserID = /* GraphQL */ `
  query GetUserByUserpoolUserID(
    $userpool_user_id: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByUserpoolUserID(
      userpool_user_id: $userpool_user_id
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        is_sso
        points {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserScore = /* GraphQL */ `
  query GetUserScore($id: ID!) {
    getUserScore(id: $id) {
      id
      points
      type
      userID
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        is_sso
        points {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUserScores = /* GraphQL */ `
  query ListUserScores(
    $filter: ModelUserScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        points
        type
        userID
        user {
          id
          userpool_user_id
          email
          phone_number
          street_address
          city
          county
          race
          ethnicity
          gender
          age_range
          zip_code
          household_size
          allow_cellphone
          allow_notification
          allow_enews
          allow_text_program
          is_participating
          is_eligible
          first_name
          last_name
          full_name
          state
          birth_year
          is_sso
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const scoreByUser = /* GraphQL */ `
  query ScoreByUser(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    scoreByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        points
        type
        userID
        user {
          id
          userpool_user_id
          email
          phone_number
          street_address
          city
          county
          race
          ethnicity
          gender
          age_range
          zip_code
          household_size
          allow_cellphone
          allow_notification
          allow_enews
          allow_text_program
          is_participating
          is_eligible
          first_name
          last_name
          full_name
          state
          birth_year
          is_sso
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCurriculumAdditionalRelation = /* GraphQL */ `
  query GetCurriculumAdditionalRelation($id: ID!) {
    getCurriculumAdditionalRelation(id: $id) {
      id
      curriculumID
      curriculumAdditionalID
      curriculum {
        id
        curriculum_name
        additional {
          nextToken
          __typename
        }
        lessons {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      curriculumAdditional {
        id
        additional_name
        snap_ed
        efnep
        cphp
        points
        description
        audience
        display_order
        curricula {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCurriculumAdditionalRelations = /* GraphQL */ `
  query ListCurriculumAdditionalRelations(
    $filter: ModelCurriculumAdditionalRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurriculumAdditionalRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        curriculumID
        curriculumAdditionalID
        curriculum {
          id
          curriculum_name
          createdAt
          updatedAt
          owner
          __typename
        }
        curriculumAdditional {
          id
          additional_name
          snap_ed
          efnep
          cphp
          points
          description
          audience
          display_order
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserActivities = /* GraphQL */ `
  query GetUserActivities($id: ID!) {
    getUserActivities(id: $id) {
      id
      activityID
      userID
      activity {
        id
        title
        description
        points
        activity_code
        users {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      user {
        id
        userpool_user_id
        email
        phone_number
        street_address
        city
        county
        race
        ethnicity
        gender
        age_range
        zip_code
        household_size
        allow_cellphone
        allow_notification
        allow_enews
        allow_text_program
        is_participating
        is_eligible
        first_name
        last_name
        full_name
        state
        birth_year
        is_sso
        points {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUserActivities = /* GraphQL */ `
  query ListUserActivities(
    $filter: ModelUserActivitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        activityID
        userID
        activity {
          id
          title
          description
          points
          activity_code
          createdAt
          updatedAt
          owner
          __typename
        }
        user {
          id
          userpool_user_id
          email
          phone_number
          street_address
          city
          county
          race
          ethnicity
          gender
          age_range
          zip_code
          household_size
          allow_cellphone
          allow_notification
          allow_enews
          allow_text_program
          is_participating
          is_eligible
          first_name
          last_name
          full_name
          state
          birth_year
          is_sso
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
