import { Pages } from '../../../../../lib/constants';
import Page from '../../../Page';
import AddSession from './CreateSession';

export const CreateSessionPage = () => {
  return (
    <Page
      title="Add Session"
      breadcrumbs={['events', ':event', 'Add Session']}
      sectionName="events/sessions"
      navPhoto={{ image: '/images/events/CREATE_EVENT_PHOTO.jpg' }}
      pageId={Pages.SESSION_CREATE}
      requireAuth
    >
      <AddSession />
    </Page>
  )
};

export default CreateSessionPage;
