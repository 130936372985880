import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import Page from '../Page';
import { PageContext } from '../../../lib/contexts/pageContext';
import UserContext from '../../../lib/contexts/userContext';
import Alerts from '../../common/Alerts';
import { ssoUrl } from '../../../lib/auth';
import { Roles } from '../../../lib/constants';

const SSO = () => {
  const { isLoggedIn, handleAuthCode, authenticating, userState: { userRole } } = useContext(UserContext);
  const { setPageErrorMessage } = useContext(PageContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handlingCode = useRef(false);

  const loggedIn = isLoggedIn(false);
  const hasCode = searchParams.size === 1 && searchParams.get('code');

  const handleCode = useCallback(async () => {
    if (handlingCode.current) return;
    try {
      handlingCode.current = true;
      const code = searchParams.get('code');
      // searchParams.delete('code');
      // setSearchParams(searchParams);
      const result = await handleAuthCode(code);
      if (result === false) {
        // navigate('/login/sso');
      }
    } catch (err) {
      setPageErrorMessage(err.message);
    }
    // eslint-disable-next-line
  }, [handleAuthCode, navigate, searchParams, setPageErrorMessage, setSearchParams]);

  useEffect(() => {
    if (hasCode) {
      // handle the ?code= returned from the SSO flow
      handleCode().then();
    }
  }, [handleCode, hasCode]);

  useEffect(() => {
    if (!hasCode && !authenticating && !loggedIn) {
      // if there is no code to process, and the user is not logged in (or logging in)
      window.location.href = ssoUrl;
    } else if (loggedIn) {
      // use is fully authenticated, sitting, awaiting redirection
      if (userRole !== Roles.USER) {
        navigate('/events');
      } else {
        navigate('/member/activities');
      }
    } else if (!hasCode && !authenticating && !loggedIn) {
      // The user hit the page accidentally, or the login process failed
      navigate('/login');
    }
    // eslint-disable-next-line
  }, [authenticating, loggedIn, navigate]);

  return (
    <Page
      title="SSO"
      hideNav
      showAlerts={false}
    >
      <Grid
        container
        gap={3}
        wrap={'nowrap'}
        className={'content-container'}
        columns={{ xs: 12, sm: 12, md: 9, lg: 10 }}
      >
        <Grid xs={12} sm={12} md={6} lg={8}>
          <Alerts />

          <Grid xs={10} textAlign="center">
            <CircularProgress/>
          </Grid>

        </Grid>
        { /** This is a placeholder to maintain the right side width as equal to the left nav **/}
        <Box
          component={Grid}
          xs={0} sm={0} md={3} lg={2}
          display={{ xs: 'none', sm: 'none', md: 'block' }}
        />
      </Grid>
    </Page>
  );
};

export default SSO;
