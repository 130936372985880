import { generateShortCode } from '../../../lib/utils';
import { graphQuery, queryDataMart } from '../../../lib/api';
import moment from '../../../lib/moment';
import { listEvents, listSessions } from './eventQueries';

/**
 * Counts the number of INCOMPLETE, COMPLETED, and CANCELED sessions in the provided array as well as the earliest
 * start date and latest end date for the provided series array.
 * It also modifies the provided array by sorting and adding an order number to each session.
 * @param sessions
 * @returns {*}
 */
export const getSessionTotals = (sessions) => {
  if (sessions.length === 0) {
    return {
      dates: null,
      counts: null,
    }
  }
  const stats = {
    dates: { start: null, end: null },
    counts: {
      COMPLETED: 0,
      INCOMPLETE: 0,
      CANCELED: 0,
      total: 0,
    }
  };

  sessions.sort((a, b) => moment(a.start).diff(moment(b.start)));

  for (let i = 0; i < sessions.length; i += 1) {
    const session = sessions[i];
    const sessionStart = moment(session.start);
    const sessionEnd = moment(session.end);

    if (!stats.dates.start) {
      stats.dates.start = sessionStart;
    } else if (sessionStart.isBefore(stats.dates.start)) {
      stats.dates.start = sessionStart;
    }
    if (!stats.dates.end) {
      stats.dates.end = sessionEnd;
    } else if (sessionEnd.isAfter(stats.dates.end)) {
      stats.dates.end = sessionEnd;
    }

    stats.counts.total += 1;
    sessions[i].order = stats.counts.total;

    if (!session.status) {
      stats.counts.INCOMPLETE += 1;
    } else {
      stats.counts[session.status] += 1;
    }
  }
  return stats;
};

export const orderSessions = (sessions) => {
  sessions.sort((a, b) => {
    const aStart = moment(a.start);
    const bStart = moment(b.start);
    return aStart - bStart;
  });
  return sessions;
};

export const getLessons = async () => {
  const query = /* GraphQL */`
    query getAllCurriculum($filter: ModelCurriculumLessonFilterInput, $limit: Int = 1000) {
      listCurriculumLessons(filter: $filter, limit: $limit) {
        items {
          lesson_name
          booth_topic
          description
          snap_ed
          efnep
          cphp
          points
          display_order
          curriculum {
            curriculum_name
            additional {
              items {
                curriculumAdditional {
                  additional_name
                }
              }
            }
          }
        }
      }
    }
  `;

  const response = await graphQuery(query);
  let curriculums = response || [];
  curriculums = curriculums.map((cur) => ({
    ...cur,
    curriculum_name: cur?.curriculum?.curriculum_name,
    additional: cur?.curriculum?.additional?.items?.map((a) => a.curriculumAdditional.additional_name),
  }));
  curriculums.sort((a, b) => {
    return parseInt(a?.display_order ?? 99999) - parseInt(b?.display_order ?? 99999);
  });

  return curriculums;
};

export const getBoothTopics = async () => {
  const query = /* GraphQL */`
    query getBoothTopics($filter: ModelCurriculumLessonFilterInput, $limit: Int = 1000) {
      listCurriculumLessons(filter: $filter, limit: $limit) {
        items {
          id
          curriculumID
          lesson_name
          booth_topic
          snap_ed
          efnep
          cphp
          points
          description
          display_order
          audience
          createdAt
          updatedAt
          owner
          __typename
        }
      }
    }
  `;

  let variables = {
    filter: {
      and: [
        { booth_topic: { attributeExists: true } },
        { booth_topic: { gt: '' } },
      ]
    }
  };

  const response = await graphQuery(query, variables);
  const boothTopics = response || [];
  boothTopics.sort((a, b) => {
    return parseInt(a?.display_order ?? 99999) - parseInt(b?.display_order ?? 99999);
  });

  return boothTopics;
};

export const getCurriculumLessons = async (curriculumID) => {
  const query = /* GraphQL */`
    query getCurriculumLessons($id: ID!) {
      getCurriculum(id: $id) {
        id
        curriculum_name
        additional {
          items {
            id
            curriculumID
            curriculumAdditionalID
            curriculumAdditional {
              id
              additional_name
              snap_ed
              efnep
              cphp
              points
              description
              audience
              display_order
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
        lessons {
          items {
            id
            curriculumID
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
      }
    }
  `;

  let variables = {
    input: { id: curriculumID },
  };

  const response = await graphQuery(query, variables);
  const lessons = response || [];
  lessons.sort((a, b) => {
    return parseInt(a?.display_order ?? 99999) - parseInt(b?.display_order ?? 99999);
  });

  return lessons;
};

export const getCurricula = async () => {
  const query = /* GraphQL */`
    query getCurricula($filter: ModelCurriculumFilterInput, $limit: Int = 1000) {
      listCurricula(filter: $filter, limit: $limit) {
        items {
          id
          curriculum_name
          additional {
            items {
              curriculumAdditional {
                id
                additional_name
              }
            }
          }
          lessons {
            items {
              lesson_name
              snap_ed
              efnep
              cphp
            }
          }
        }
      }
    }
  `;

  let curriculums = await graphQuery(query);
  curriculums = curriculums.map((cur) => ({
    ...cur,
    additional: cur?.additional?.items?.map((a) => a.curriculumAdditional),
    program_areas: cur?.lessons?.items?.reduce((acc, lesson) => {
      ['snap_ed', 'efnep', 'cphp'].forEach((key) => {
        if (lesson[key] && !acc.includes(key)) {
          acc.push(key);
        }
      });
      return acc;
    }, []),
  }));
  curriculums.sort((a, b) => a.curriculum_name.localeCompare(b.curriculum_name));
  return curriculums;
};

/**
 * Retrieve dropdown options from PEARs for create event form fields.
 * Currently, this is a placeholder until we get credentials for the PEARs database.
 */
export const getPearsData = async () => {
  const handleError = (err) => {
    console.log('Pears data error:', err);
    return { data: [] };
  };
  const [{ data: sites }, { data: projects }, { data: languages }] = await Promise.all([
    queryDataMart(`
      SELECT
        id, name, address, city, state_abbreviation, zip_code, setting, is_active
      FROM
        site
    `).catch(handleError),
    queryDataMart(`
      SELECT
          cqo.text
      FROM
          custom_question_option AS cqo
      WHERE
          EXISTS (
              SELECT 1
              FROM
                  custom_form_custom_question AS cfcq
                      INNER JOIN
                  custom_question_program_area AS cqpa ON cfcq.custom_question_id = cqpa.custom_question_id
              WHERE
                  cqo.custom_question_id = cfcq.custom_question_id
                AND cfcq.custom_question_id IN (
                  SELECT id
                  FROM custom_question
                  WHERE display_slug = '${process.env.REACT_APP_SPECIAL_PROJECTS_ID}'
              )
                AND cqpa.program_area_id = 1010 -- SNAP-ED program area ID
          );
   `).catch(handleError),
    queryDataMart('SELECT name, slug FROM list_language').catch(handleError),
  ]);
  const { partnerSites, mainOffices } = sites.reduce((acc, site) => {
    const name = `${site.name} (${site.address}, ${site.city}, ${site.state_abbreviation} ${site.zip_code})`;
    const isDualSite = [156457, 25835].includes(site.id);
    if (!acc.partnerSites.includes(name)) {
      acc.partnerSites.push(name);
    }
    if ((site.name.includes('U of I Extension -') || site.name.includes('U of I at Chicago - CPHP office UIC'))
      && site.is_active === 1
      && site.setting === 'Extension offices'
      && !acc.mainOffices.includes(name)
    ) {
      acc.mainOffices.push(name);
    } else if (isDualSite) {
      acc.mainOffices.push(name);
    }
    return acc;
  }, { partnerSites: [], mainOffices: []});
  return {
    partners: partnerSites.sort(),
    main_offices: mainOffices.sort(),
    special_projects: projects.map((p) => p.text).sort(
      (a, b) => {
        if (a === 'None') {
          return -1;
        } else if (b === 'None') {
          return 1;
        }
        return a.localeCompare(b);
      }),
    languages,
  };
};

const shortCodeIsUniqueEvent = async (shortCode) => {
  const events = await graphQuery(listEvents, { filter: { short_code: shortCode }});
  return events.length === 0;
};

export const generateUniqueShortCodeEvent = async () => {
  let shortCode = generateShortCode();
  const unique = await shortCodeIsUniqueEvent(shortCode);
  if (unique) {
    return shortCode;
  } else {
    return await generateUniqueShortCodeEvent();
  }
};

const shortCodeIsUnique = async (shortCode) => {
  const sessions = await graphQuery(listSessions, { filter: { short_code: { eq: shortCode }}});
  return sessions.length === 0;
};

export const generateUniqueShortCode = async () => {
  let shortCode = generateShortCode();
  const unique = await shortCodeIsUnique(shortCode);
  if (unique) {
    return shortCode;
  } else {
    return await generateUniqueShortCode();
  }
};
