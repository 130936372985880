import React from 'react';
import SignUp from './SignUp';
import photo from '../../../sass/images/signup/photo.jpg';
import Page from '../Page';

const SignUpPage = () => {
  return (
    <Page
      title="Sign Up Form"
      breadcrumbs={['Sign Up']}
      navPhoto={{ image: photo }}
      rightSidebar
      divider
    >
      <SignUp />
    </Page>
  )
}

export default SignUpPage;
