import React, {useContext} from 'react';
import {Box} from '@mui/system';
import {PageContext} from '../../../lib/contexts/pageContext';
import {styled} from '@mui/material';

const SectionBar = (props) => {
  const { pathData } = useContext(PageContext);

  const { pageName, sectionName } = {
    ...pathData,
    ...props,
  };

  const icons = {
    'signup': '/images/section-bars/icons/signup-ICON.svg',
    'verify': '/images/section-bars/icons/signup-ICON.svg',
    'login': '/images/section-bars/icons/login-ICON.svg',
    'checkin': '/images/section-bars/icons/checkin-ICON.svg',
    'profile': '/images/section-bars/icons/member-ICON.svg',
    // 'password': '/images/section-bars/icons/member-ICON.svg',
    'password': '/images/section-bars/icons/login-ICON.svg',
    'activities': '/images/section-bars/icons/member-ICON.svg',
    'create': '/images/section-bars/icons/create_event-ICON.svg',
    'event': '/images/section-bars/icons/events-ICON.svg',
    'sessions': '/images/section-bars/icons/events-ICON.svg',
  };

  const isHome = sectionName === 'home';

  const SectionBarBox = styled(Box)({
    ...(isHome ? {
      minHeight: '300px',
      paddingTop: '1rem',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    } : {
      '& div': { marginRight: '8px' }
    })
  });

  return (
    <SectionBarBox className="mui-section-bar">
      {(!!pageName && !isHome && icons[pageName]) &&
        <Box className="page-icon">
          <img src={icons[pageName]} alt='site logo' />
        </Box>
      }
      {!isHome && (
        <Box className='section-title'>{sectionName}</Box>
      )}
    </SectionBarBox>
  )
}

export default SectionBar;
