import { Pages } from '../../../../lib/constants';
import Page from '../../Page';
import EditEvent from './EditEvent';
import { useState } from 'react';

export const EditEventPage = () => {
  const [title, setTitle] = useState('Edit Event');
  return (
    <Page
      title={title}
      breadcrumbs={['events', ':event', 'Edit Event']}
      sectionName="events"
      navPhoto={{ image: '/images/events/CREATE_EVENT_PHOTO.jpg' }}
      pageId={Pages.EVENT_EDIT}
    >
      <EditEvent setTitle={setTitle} />
    </Page>
  )
};

export default EditEventPage;
