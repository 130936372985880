import { Link as RouterLink } from 'react-router-dom';
import { Link, TableCell, TableContainer } from '@mui/material';
import React from 'react';
import { defaultComparator } from '../../../../lib/utils';
import MuiTable from '../../../common/MuiTable';
import {
  formatSessionDates,
  getDeliveryCells,
  getDeliveryInfo,
  getSessionName,
  getStatusIcon
} from './sessionFunctions';
import SessionStatusLegend from './SessionStatusLegend';

/**
 * Returns a comparison function.
 *
 * @param order
 * @param orderBy
 */
function getComparator(order, orderBy) {
  const ord = order === 'desc' ? -1 : 1;
  switch (orderBy) {
    case 'start':
      return (a, b) => {
        if (a === b) return 0;
        if (!a?.mStart) return -1 * ord;
        if (!b?.mStart) return 1 * ord;
        return a.mStart.diff(b.mStart) * ord;
      };
    case 'status':
      const getStatusOrd = (status) => {
        switch (status) {
          case 'COMPLETED':
            return 2;
          case 'CANCELED':
            return 0;
          case 'INCOMPLETE':
          default:
            return 1;
        }
      };
      return (a, b) => getStatusOrd(a.status) - getStatusOrd(b.status);
    case 'delivery_method':
    case 'delivery_site':
      let column = 'methods';
      if (orderBy === 'delivery_site') {
        column = 'details';
      }
      return (a, b) => {
        const { [column]: aStrings } = getDeliveryInfo(a);
        const { [column]: bStrings } = getDeliveryInfo(b);
        return aStrings.join('').localeCompare(bStrings.join('')) * ord;
      }
    case 'session_name':
      return (a, b) => {
        const aName = getSessionName({
          session: a,
          showNumbers: false,
        });
        const bName = getSessionName({
          session: b,
          showNumbers: false,
        });
        return aName.localeCompare(bName) * ord;
      };
    case 'created_by':
      return (a, b) => {
        const aName = a?.created_by_names || a?.created_by;
        const bName = b?.created_by_names || b?.created_by;
        return aName.localeCompare(bName) * ord;
      };
    default:
      return defaultComparator;
  }
};

const SessionsTable = (props) => {
  const { sessions } = props;

  const headCells = [
    {
      id: 'start',
      label: 'Date & Time',
    },
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'session_name',
      label: 'Session Name',
    },
    {
      id: 'delivery_method',
      label: 'Delivery Method',
    },
    {
      id: 'delivery_site',
      label: 'Delivery Site & Address',
    },
    {
      id: 'created_by',
      label: 'Created By',
    },
  ];

  return (
    <>
      <SessionStatusLegend />
      <TableContainer sx={{ marginTop: '32px' }}>
        <MuiTable
          defaultOrderBy="start"
          headCells={headCells}
          getComparator={getComparator}
          data={sessions}
          alignTop
        >
          {(row) => (
            <>
              {/** date, lesson/booth, delivery method, site, created by **/}
              <TableCell align="left" sx={{ minWidth: '210px' }}>
                <Link to={`/events/${row.eventID}/${row.id}/`} component={RouterLink}>
                  { formatSessionDates(row.start, row.end) }
                </Link>
              </TableCell>
              <TableCell component="th" scope="row">
                { getStatusIcon(row.status) }
              </TableCell>
              <TableCell component="th" scope="row">
                { getSessionName({ session: row, useNill: true, dateFallback: false }) }
              </TableCell>
              { getDeliveryCells(row) }
              <TableCell align="left">{row.created_by_names}</TableCell>
            </>
          )}
        </MuiTable>
      </TableContainer>
    </>
  );
};

export default SessionsTable;
