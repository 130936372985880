import { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Paper } from '@mui/material';
import { Box } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import Page from '../Page';
import { PageContext } from '../../../lib/contexts/pageContext';
import UserContext from '../../../lib/contexts/userContext';
import Alerts from '../../common/Alerts';

const Login = () => {
  const { isLoggedIn, isAdmin, loadingRecord, authenticating, isSSO } = useContext(UserContext);
  const { pathData: { pageName } } = useContext(PageContext);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/login' && searchParams.get('code')) {
      // temporary redirection ?code= until the SSO redirect is updated to /login/sso
      navigate(`/login/sso?${searchParams.toString()}`);
      return;
    }
    if (!authenticating && isLoggedIn(false)) {
      // console.log('checking for isSSO', userSession);
      const redirect = searchParams.get('redirect');
      if (redirect) {
        navigate(redirect);
      } else if (isAdmin()) {
        navigate('/member/profile');
      } else if (isSSO()) {
        navigate('/events');
      } else {
        navigate('/member/activities');
      }
    }
    // eslint-disable-next-line
  }, [authenticating]);

  let title = 'Login';
  let breadcrumbs = ['login'];
  switch (pageName) {
    case 'password':
      title = 'Reset your password';
      breadcrumbs = ['login', 'Reset Password'];
      break;
    case 'verify':
      title = 'Verification';
      breadcrumbs = ['login', 'Password Verification'];
      break;
    default:
      break;
  }

  return (
    <Page
      title={title}
      breadcrumbs={breadcrumbs}
      hideNav
      showAlerts={false}
    >
      <Grid container gap={3} wrap={'nowrap'} className={'content-container'}
            columns={{ xs: 12, sm: 12, md: 9, lg: 10 }}>
        <Grid xs={12} sm={12} md={6} lg={8}>
          <Alerts />
          <Paper sx={{ padding: '50px 20px' }} variant="outlined">
            <Grid container columns={12}>
              <Grid xs={0} sm={2}></Grid>
              <Grid xs={12}>
                <Outlet />
              </Grid>
            </Grid>
          </Paper>

          {(!loadingRecord && pageName === 'login') && (
            <>
              <Box display="flex" justifyContent={'center'} width="100%" mt="50px">
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    navigate('/signup');
                  }}
                >
                  Sign Up
                </Button>
              </Box>
            </>
          )}
        </Grid>
        { /** This is a placeholder to maintain the right side width as equal to the left nav **/}
        <Box
          component={Grid}
          xs={0} sm={0} md={3} lg={2}
          display={{ xs: 'none', sm: 'none', md: 'block' }}
        />
      </Grid>
    </Page>
  );
};

export default Login;
