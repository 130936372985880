import {Box, Stack} from "@mui/system";
import {Typography} from "@mui/material";
import React from "react";
import {getStatusIcon} from "./sessionFunctions";

const SessionStatusLegend = () => (
    <Stack direction="row" spacing={2} sx={{
        marginTop: '32px', marginBottom: '-16px', justifyContent: 'flex-end'
    }}>
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            { getStatusIcon('CANCELED') }
            <Typography variant="body" sx={{ marginLeft: '4px' }}>
                Canceled
            </Typography>
        </Box>
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            { getStatusIcon('') }
            <Typography variant="body" sx={{ marginLeft: '4px' }}>
                Incomplete
            </Typography>
        </Box>
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            { getStatusIcon('COMPLETED') }
            <Typography variant="body" sx={{ marginLeft: '4px' }}>
                Completed
            </Typography>
        </Box>
    </Stack>
);
export default SessionStatusLegend;
