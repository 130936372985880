import { FormContainer } from 'react-hook-form-mui';
import Grid from '@mui/material/Unstable_Grid2';
import Alerts from './Alerts';
import { List } from '@mui/material';
import React, { Fragment, useCallback, useState } from 'react';
import { getArrayMsg } from '../../lib/utils';
import useErrorHandling from './hooks/useErrorHandling';
import { useForm } from 'react-hook-form';

const ModalForm = ({ fields, onSubmit, submitRef, defaultValues }) => {
  const [pageMessages, setMessages] = useState(null);
  const [pageErrorMessages, setErrorMessages] = useState(null);
  const [wasSubmitting, setWasSubmitting] = useState(false);

  const form = useForm({
    mode: 'onSubmit',
    defaultValues,
  });

  const setPageMessage = useCallback((msg) => setMessages(getArrayMsg(msg)), []);
  const setPageErrorMessage = useCallback((msg) => setErrorMessages(getArrayMsg(msg)), []);

  const modalContext = { setPageMessage, setPageErrorMessage, pageMessages, pageErrorMessages };

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = form;

  useErrorHandling({
    errors,
    isSubmitting,
    wasSubmitting,
    setWasSubmitting,
    modalContext,
    scrolling: false,
  });

  return (
    <FormContainer onSuccess={handleSubmit(onSubmit)} FormProps={{ className: 'mui' }}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Alerts modalContext={modalContext} />
        </Grid>
        <Grid xs={12} sm={10} md={8}>
          <List sx={{
            listStyle: 'decimal',
            paddingLeft: 2,
          }}>
            { fields(control).map((field, i) => (
              <Fragment key={`field-${i}`}>
                { field }
              </Fragment>
            )) }
          </List>
        </Grid>
      </Grid>
      <button type="submit" style={{ visibility: 'hidden' }} ref={submitRef} />
    </FormContainer>
  );
}

export default ModalForm;
