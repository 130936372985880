import { useAbility } from '@casl/react';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../../../lib/contexts/userContext';
import { createFilter } from '../../../lib/utils';
import { AbilityContext, toGraphQuery } from '../../../lib/permissions';

const usePermissionFilter = ({
  id = null,
  additionalFilter = null,
  subject,
  action,
}) => {
  const [status, setStatus] = useState('AUTHENTICATING');
  const [filter, setFilter] = useState(null);

  const { authenticated } = useContext(UserContext);

  const ability = useAbility(AbilityContext);

  useEffect(() => {
    if (authenticated) {
      setStatus('AUTHORIZING');

      if (ability.cannot(action, subject)) {
        setStatus('UNAUTHORIZED');
      } else {
        const filterRules = toGraphQuery(ability, action, subject);
        const filter = createFilter(id, filterRules, additionalFilter);
        setFilter(filter);
        setStatus('COMPLETE');
      }
    }
    // eslint-disable-next-line
  }, [ability, authenticated]);

  return {
    status,
    filter,
  }
};

export default usePermissionFilter;
