import { Pages } from '../../../../../lib/constants';
import PastSessions from './PastSessions';
import Page from '../../../Page';
import { useState } from 'react';

const PastSessionsPage = () => {
  const [title, setTitle] = useState('Sessions - Past');
  return (
    <Page
      sectionName="sessions"
      breadcrumbs={['pastSessions']}
      pageId={Pages.PAST_SESSIONS}
      navPhoto={{ image: '/images/activities/left_sidebar.jpg' }}
      title={title}
      requireAuth
    >
      <PastSessions setTitle={setTitle} />
    </Page>
  )
};

export default PastSessionsPage;
