import { useState } from 'react';
import { Pages } from '../../../../lib/constants';
import EventDetails from './EventDetails';
import Page from '../../Page';

const EventDetailsPage = () => {
  const [title, setTitle] = useState('');

  return (
    <Page
      pageId={Pages.EVENT_DETAILS}
      navPhoto={{ image: '/images/activities/left_sidebar.jpg' }}
      title={title}
      breadcrumbs={['events', ':event']}
      requireAuth
      loaderOffset={false}
    >
      <EventDetails setTitle={setTitle} />
    </Page>
  )
};

export default EventDetailsPage;
