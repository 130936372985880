import React from 'react';
import { CircularProgress } from '@mui/material';
import moment from '../../../../../lib/moment';
import useEventSessionQuery from '../../../../common/hooks/useEventSessionQuery';
import SessionsTable from '../SessionsTable';
import Grid from '@mui/material/Grid';
import { Subjects } from '../../../../../lib/permissions';
import DataEmptyMessage from "../../../../common/DataEmptyMessage";
import DataLoadingError from "../../../../common/DataLoadingError";
import OverlaySpinner from '../../../../common/OverlaySpinner';

const PastSessions = () => {
  const now = moment();

  const {
    data: sessions,
    loading,
    refetching,
    error,
    isError,
  } = useEventSessionQuery({
    permissions: { action: 'edit', subject: Subjects.EVENT },
    selectFn: (events) => {
      if (!events || events.length === 0) {
        return [];
      }

      const sessions = [];
      events.forEach((event) => {
        event.sessions?.items.forEach((session) => {
          session.event = event;
          session.mStart = moment(session.start);
          session.mEnd = moment(session.end);
          if (session.mEnd && now.isAfter(session.mEnd)) {
            sessions.push(session);
          }
        })
      });
      return sessions;
    },
  });

  return (
    <div id="sessions" style={{ position: 'relative' }}>
      <OverlaySpinner condition={refetching} />
      <Grid container spacing={1}>
        { loading && (
          <Grid item xs={12} textAlign="center">
            <CircularProgress />
          </Grid>
        )}
        { !loading && !isError && (sessions?.length > 0 ? (
          <Grid item xs={12}>
            <SessionsTable sessions={sessions} />
          </Grid>
        ) : (
          <DataEmptyMessage title={"You have no past sessions."} />
        ))}

        {isError && (
          <DataLoadingError title={"Failed to load sessions!"} errors={error.errors} />
        )}
      </Grid>
    </div>
  );
};

export default PastSessions;
