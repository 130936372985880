import { useQueryClient } from '@tanstack/react-query';

const useInvalidateQuery = () => {
  const queryClient = useQueryClient();
  return (queryKey) => queryClient.invalidateQueries({
    predicate: (query) => query.queryKey.includes(queryKey),
  });
}

export default useInvalidateQuery;
