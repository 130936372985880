import { createContext, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { capitalize, getArrayMsg, last } from '../utils';

function getPathData(location) {
  const path = [];
  const pathName = location.pathname.replace(/^\/+|\/+$/g, '');
  const parts = !!pathName ? pathName.split('/') : [];

  // special case for INEP Create Event
  if (location.pathname.includes('INEP=')) {
    path.push('events', 'create');
  } else {
    parts.forEach((part) => path.push(part));
  }

  const sectionName = path.length ? path[0] : 'home';
  const pageName = path.length ? last(path) : 'home';
  const pageNameCap = capitalize(pageName);

  const crumbs = [];

  return {
    level: path.length ? path.length - 1 : 0,
    path,
    crumbs,
    sectionName,
    pageName,
    pageNameCap
  };
}

export const PageContext = createContext({ pageMessage: '', pageErrorMessage: '' });

export const PageProvider = ({ children }) => {
  const location = useLocation();
  const [pathData, setPathData] = useState(getPathData(location));
  const [breadcrumbValues, setBreadcrumbValues] = useState({});
  const [pageMessages, setMessages] = useState(null);
  const [pageErrorMessages, setErrorMessages] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [authorized, setAuthorized] = useState(null);

  const setPageMessages = useCallback((msg) => {
    setMessages(getArrayMsg(msg));
  }, []);

  const setPageErrorMessages = useCallback((msg) => {
    setErrorMessages(getArrayMsg(msg));
  }, []);

  const appendMessages = useCallback((msg) => {
    if (!msg) {
      setPageMessages(null);
    } else if (Array.isArray(msg)) {
      if (msg.length > 0) {
        pageMessages.push(...msg);
        setPageMessages(pageMessages);
      } else {
        setPageMessages(null);
      }
    } else {
      pageMessages.push(msg);
      setPageMessages(pageErrorMessages);
    }
  }, [pageErrorMessages, pageMessages, setPageMessages]);

  const appendErrorMessages = useCallback((msg) => {
    if (!msg) {
      setPageErrorMessages(null);
    } else if (Array.isArray(msg)) {
      if (msg.length > 0) {
        pageErrorMessages.push(...msg);
        setPageErrorMessages(pageMessages);
      } else {
        setPageErrorMessages(null);
      }
    } else {
      pageErrorMessages.push(msg);
      setPageErrorMessages(pageErrorMessages);
    }
  }, [pageErrorMessages, pageMessages, setPageErrorMessages]);

  /**
   * Set the value for a placeholder breadcrumb
   * @param {string} key The name of the placeholder, e.g., ':event'
   * @param {object} value The name and ID
   */
  const setBreadcrumbValue = useCallback((key, value) => {
    setBreadcrumbValues(crumbs => ({
      ...crumbs,
      [key]: value
    }));
  }, []);

  const clearBreadcrumbValues = useCallback(() => setBreadcrumbValues([]), []);

  // When the authenticated user changes, load a new user record and update session state variables
  useEffect(() => {
    const updatedPathData = getPathData(location);
    setPathData(updatedPathData);
  }, [location]);

  return (
    <PageContext.Provider value={{
      pathData,
      breadcrumbValues,
      setBreadcrumbValue,
      clearBreadcrumbValues,
      pageMessages,
      setPageMessages,
      setPageMessage: setPageMessages,
      pageErrorMessages,
      setPageErrorMessages,
      setPageErrorMessage: setPageErrorMessages,
      appendMessages,
      appendErrorMessages,
      pageLoading,
      setPageLoading,
      authorized,
      setAuthorized,
    }}>
      {children}
    </PageContext.Provider>
  );
};
