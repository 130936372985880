import { Pages } from '../../../../../lib/constants';
import UpcomingSessions from './UpcomingSessions';
import Page from '../../../Page';
import { useState } from 'react';

const UpcomingSessionsPage = () => {
  const [title, setTitle] = useState('Sessions - Upcoming');

  return (
    <Page
      sectionName="sessions"
      breadcrumbs={['upcomingSessions']}
      pageId={Pages.UPCOMING_SESSIONS}
      navPhoto={{ image: '/images/activities/left_sidebar.jpg' }}
      title={title}
      requireAuth
    >
      <UpcomingSessions setTitle={setTitle} />
    </Page>
  )
};

export default UpcomingSessionsPage;
