import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useAbility } from '@casl/react';
import { PageContext } from '../../../../../lib/contexts/pageContext';
import Grid from '@mui/material/Unstable_Grid2';
import { API, graphqlOperation } from 'aws-amplify';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import {
  scrollToAlert,
  sortDisplayOrder,
} from '../../../../../lib/utils';
import { AbilityContext, Subjects } from '../../../../../lib/permissions';
import { subject } from '@casl/ability';
import UserContext from '../../../../../lib/contexts/userContext';
import { useNavigate, useParams } from 'react-router-dom';
import SessionForm from '../SessionForm';
import { useForm } from 'react-hook-form';
import { updateSession } from '../../../../../graphql/mutations';
import moment from '../../../../../lib/moment';
import { getSessionName } from '../sessionFunctions';
import useInvalidateQuery from '../../../../common/hooks/useInvalidateQuery';
import { graphQuery } from '../../../../../lib/api';
import { listEventsWithLessons } from '../../eventQueries';
import { getBoothTopics, getPearsData } from '../../eventFunctions';
import usePermissionFilter from '../../../../common/hooks/usePermissionFilter';

const EditSession = ({ setTitle }) => {
  const [formData, setFormData] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const valuesSetState = useState(null);

  const { setBreadcrumbValue, setPageMessage, setPageErrorMessage } = useContext(PageContext);
  const { userState: { userRecord }, authenticated, getStaffUsersWithCurrentUser } = useContext(UserContext);

  const ability = useAbility(AbilityContext);

  const invalidateQueries = useInvalidateQuery();

  const navigate = useNavigate();

  const { eventID, sessionID } = useParams();

  const { filter } = usePermissionFilter({
    action: 'edit',
    subject: Subjects.EVENT,
    id: eventID,
  });

  const form = useForm({
    mode: 'onSubmit',
    shouldFocusError: false,
    defaultValues: {
      session_name: null,
      lesson: null,
      start: null,
      end: null,
      has_wifi: null,
      delivery_method: [],
      delivery_url: null,
      delivery_location: null,
      other_details: null,
      recipes: null,
      delivery_language: [],
      material_language: [],
      demonstration: null,
    }
  });

  const fetchSession = useCallback(async () => {
    try {
      // Filter the event based on user permissions (if they can't view it, it won't return)
      const result = await graphQuery(listEventsWithLessons, { filter });
      const [eventData] = result || null;
      const sessionData = eventData.sessions.items.find((s) => s.id === sessionID);
      sessionData.event = eventData;
      sessionData.name = getSessionName({
        session: sessionData,
        showNumbers: false,
        dateFallback: true,
        useNill: false,
      });
      return sessionData;
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [eventID, sessionID]);

  /** Pull curricula and staff users on load **/
  useEffect(() => {
    if (!eventID || !sessionID || !authenticated || !filter) {
      return;
    }
    let active = true;
    Promise.all([
      fetchSession(filter).then((sessionData) => {
        if (!active) {
          return;
        }
        return sessionData;
      }),
      getBoothTopics(),
      getStaffUsersWithCurrentUser(),
      getPearsData(),
    ]).then(([sessionData, boothTopics, staff, pears]) => {
      if (!active) {
        console.warn('[EditSession] fetch completed but render is no longer active...')
        return;
      }
      pears.languages = pears.languages.map((lang) => ({ id: lang.slug, label: lang.name }));
      pears.languageLabels = pears.languages.map((lang) => (lang.label));
      if (sessionData.event.event_type === 'CLASS' && sessionData.event.curriculum?.lessons) {
        sortDisplayOrder(sessionData.event.curriculum.lessons.items, 'lesson_name');
      }
      if (sessionData) {
        setFormData({
          session: sessionData,
          event: sessionData.event,
          boothTopics,
          staff,
          pears,
        });
      } else {
        setFormData(false);
        setTitle('Error Accessing Page');
      }
    });
    return () => {
      active = false;
    }
    // eslint-disable-next-line
  }, [authenticated, eventID, sessionID, fetchSession, filter]);

  useEffect(() => {
    if (formData?.session) {
      setBreadcrumbValue(':event', formData.session.event);
      setBreadcrumbValue(':session', formData.session);
    }
    // eslint-disable-next-line
  }, [formData?.session]);

  /**
   * Submit handler.
   *
   * @param values
   */
  const onSubmit = async (values) => {
    setPageMessage(null);
    setPageErrorMessage(null);
    setSubmitting(true);

    const input = Object.keys(values).reduce((acc, key) => {
      const value = values[key];
      switch (key) {
        case 'lesson':
          if (value?.id) {
            acc[`${key}ID`] = value?.id;
          }
          if (formData.event.event_type === 'BOOTH') {
            acc.session_name = value?.label;
          }
          break;
        case 'start':
        case 'end':
          acc[key] = moment(value).toISOString(true);
          break;
        case 'session_name':
        case 'has_wifi':
        case 'delivery_method':
        case 'delivery_url':
        case 'delivery_location':
        case 'other_details':
        case 'recipes':
          acc[key] = value?.id || value;
          break;
        case 'delivery_language':
        case 'material_language':
          acc[key] = (value || []);
          break;
        case 'demonstration':
          acc[key] = value === 'true';
          break;
        default: break;
      }
      return acc;
    }, {
      id: formData.session.id,
      eventID: formData.event.id,
      updated_by: userRecord.email,
    });

    if (ability.cannot('edit', subject(Subjects.SESSION, { ...formData.session, ...input }))) {
      setSubmitting(false);
      setPageErrorMessage('You do not have permission to edit this session.');
      scrollToAlert();
      return false;
    }

    try {
      await API.graphql({
        ...graphqlOperation(updateSession, { input }),
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      await invalidateQueries('listEvents');
      setSubmitting(false);
      navigate(`/events/${eventID}/${sessionID}`, { state: { message: 'Session updated' }});
    } catch (error) {
      console.log('Error updating session: ', error);
      setSubmitting(false);
      setPageErrorMessage('Error updating session. Please try again.');
      scrollToAlert();
    }
  };
  // if (!formData) {
  //   console.log('[EditSession][Loader]', formData);
  // }
  return (
    <Grid container gap={3} wrap={'nowrap'} columns={10}>
      <Grid xs={10}>
        { formData === false && (
          <>You do not have access, or the event was not found.</>
        )}
        { formData === null && (
          <Box width="100%" textAlign="center">
            <CircularProgress />
          </Box>
        )}
        { !!formData && (
          <SessionForm
            form={form}
            session={formData.session}
            event={formData.event}
            pearsData={formData.pears}
            staffUsers={formData.staff}
            boothTopics={formData.boothTopics}
            submitting={submitting}
            onSubmit={onSubmit}
            valuesSetState={valuesSetState}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default EditSession;
