import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import React from 'react';

/**
 * Spinner overlay that displays a spinner in the middle of the parent.
 * A gray overlay is displayed underneath the other layers so that the content is still interactable.
 * @param condition Boolean that hides or show the spinner
 * @returns {JSX.Element}
 * @constructor
 */
const OverlaySpinner = ({ condition = true }) => (
  condition && (
    <>
      <Box sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        zIndex: -1,
      }} />
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
      }}>
        <CircularProgress sx={{ margin: 'auto auto' }}/>
      </Box>
    </>
  )
);

export default OverlaySpinner;
