import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { getUserByUserpoolUserID } from '../../queries';
import { useNavigate } from 'react-router-dom';
import { createCognitoUserFromSession, getStoredTokens, getTokenByCode } from '../auth';
import storage from '../storage';
import { roleFromGroups, roleFromString, Roles } from '../constants';
import { PageContext } from './pageContext';
import { AbilityContext, defaultAbility, definePermissions } from '../permissions';
import { getStaffUsers, getUserRole } from '../api';
import { useAbility } from '@casl/react';
import { getFullName } from '../utils';

/**
 * @typedef {*} Any
 */

export const UserContext = createContext({});

const getUserRecord = async (userpool_user_id) => {
  try {
    const response = await API.graphql(graphqlOperation(
      getUserByUserpoolUserID,
      { userpool_user_id: userpool_user_id }
    ));
    return response.data.getUserByUserpoolUserID.items[0];
  } catch (error) {
    console.log('Error: ', error);
  }
};

export const isSessionSSO = (session) => session && !!session?.signInUserSession?.idToken?.payload?.identities?.find(
  (identity) => identity.providerName === 'illinois.edu',
);

export const UserProvider = (props) => {
  const [userState, setUserState] = useState({
    userSession: null,
    userRecord: null,
    userRole: null,
  });
  const [loadingRecord, setLoadingRecord] = useState(false);
  // We always attempt authentication on page load so this starts as true
  const [authenticating, setAuthenticating] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  const { setPageErrorMessage } = useContext(PageContext);
  const ability = useAbility(AbilityContext);

  const navigate = useNavigate();

  // Additional "authenticating" status reference to avoid issues in state updates
  const authRef = useRef(false);

  // Note: Had to use ref so that calls to isAdmin and isUser would reflect immediate changes after sign in.
  const userStateRef = useRef(userState);

  const getSessionRef = () => userStateRef.current?.userSession;
  const getRecordRef = () => userStateRef.current?.userRecord;
  const getRoleRef = () => userStateRef.current?.userRole;

  /**
   * Update the current authenticated user state.
   *
   * @param state { null | object } updated state object (null to clear all state)
   * @param state.userSession Cognito session with tokens
   * @param state.userRecord DynamoDB record containing user attributes
   * @param state.userRole User role symbol
   * @param anon Whether to set userRole to ANON (when user is not logged in)
   */
  const updateState = (state, anon = false) => {
    // console.log('[UserContext][updateState] state', state);
    let updatedState = {};
    if (state) {
      updatedState = { ...userState, ...state };
    } else {
      updatedState = { userSession: null, userRecord: null, userRole: anon ? Roles.ANON : null };
      setAuthenticated(false);
    }
    userStateRef.current = updatedState;
    setUserState(updatedState);
    if (updatedState?.userRole && updatedState?.userRecord) {
      definePermissions(ability, updatedState);
      setAuthenticated(true);
      // console.log('[UserContext][updateState] authenticated and permissions defined');
    }
    if (updatedState.userSession === null) {
      storage.clear();
    }
  };

  const signIn = async (email, password) => {
    const session = await Auth.signIn(email, password);
    await loadUserRecord(session);
    return session;
  };

  const signOut = async () => {
    // If there is an error signing out, just clear the storage because we don't care
    await Auth.signOut({ global: true });
    navigate('/');
    updateState(null);
  };

  const reloadUserRecord = async () => {
    if (userState.session?.username) {
      return loadUserRecord(userState.session);
    }
    return Promise.reject('No user record');
  };

  const updateUserRecord = (record) => {
    updateState({ userRecord: record });
  };

  const hasStoredTokens = () => {
    const tokens = getStoredTokens();
    return !(!tokens || !tokens.accessToken || !tokens.idToken || !tokens.refreshToken);
  };

  const isSSO = (session = userState?.userSession) => isSessionSSO(session);

  const hasRole = (roles) => {
    if (Array.isArray(roles)) {
      return roles.includes(getRoleRef());
    }
    return roles === getRoleRef();
  };

  /**
   * User is "logged in" if they have a session and user record stored in memory OR session tokens in storage (meaning
   * they will be re-authenticated on the next request).
   * @returns {boolean}
   */
  const isLoggedIn = (justTokens = true) => {
    const hasSession = !!userState.userSession?.signInUserSession && !!userState.userRecord?.id;
    const hasTokens = hasStoredTokens();
    return (hasSession || (justTokens && hasTokens));
  };

  const isUser = () => {
    const groups = getSessionRef()?.signInUserSession?.idToken.payload["cognito:groups"] || [];
    return groups.includes('Users');
  };

  const isAdmin = (session = getSessionRef()) => {
    const groups = session?.signInUserSession?.idToken.payload["cognito:groups"] || [];
    return groups.includes('Educators');
  };

  const loadUserRecord = useCallback(async (userSession) => {
    // console.log('[userContext] Loading user record...', userSession);
    if (!userSession?.username) {
      console.error('No username in loadUserRecord userSession');
      return;
    }
    setLoadingRecord(true);
    let roleError = false;
    try {
      const [recordResult, roleResult] = await Promise.all([
        getUserRecord(userSession.username),
        getUserRole(userSession.signInUserSession.idToken.payload.email),
      ]);
      const myEmsRole = roleResult?.data?.myEmsRole ?? null;
      let role = roleFromString(myEmsRole);
      if (isSSO(userSession)) {
        if (roleResult === 'User not found' || myEmsRole === '') {
          // throw error
          // You do not have access to the myEat.Move.Save. application. Please contact <support email address> for assistance.
          setPageErrorMessage('You do not have access to the myEat.Move.Save. application. Please contact <support email address> for assistance.');
          roleError = true;
        } else if (!myEmsRole) {
          // throw error
          // There has been an error determining your level of access to the myEat.Move.Save. application. Please contact <support email address> for assistance.
          setPageErrorMessage('There has been an error determining your level of access to the myEat.Move.Save. application. Please contact <support email address> for assistance.');
          roleError = true;
        }
      } else if (isAdmin(userSession)) {
        role = Roles.SUPER_ADMIN;
      } else {
        role = roleFromGroups(userSession.signInUserSession.idToken.payload['cognito:groups'])
      }
      if (!roleError) {
        if (recordResult?.id) {
          const userRecord = {
            ...recordResult,
            first_name: roleResult?.data?.firstName ?? recordResult?.first_name,
            last_name: roleResult?.data?.lastName ?? recordResult?.last_name,
          };
          updateState({ userSession, userRecord, userRole: role });
        } else {
          console.error('[UserContext] No user record and/or user role retrieved.');
          updateState({ userRecord: null, userRole: null });
        }
      } else {
        updateState(null, true);
        setLoadingRecord(false);
      }
    } catch (err) {
      console.log(err);
      updateState({ userRecord: null, userRole: Roles.ANON });
      setLoadingRecord(false);
      throw err;
    }
    // eslint-disable-next-line
  }, [userState]);

  /**
   * Check to see that we have both Cognito and myEMS users properly loaded and authenticated on page load.
   */
  const pageLoadRestoreAuth = useCallback(async () => {
    if (authRef.current) {
      return;
    }
    try {
      authRef.current = true;
      setAuthenticating(true);
      setAuthenticated(false);

      // Check to see if we have an authenticated user
      let cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
      // console.log('[userContext] current authenticated user', cognitoUser);
      if (!cognitoUser) {
        // No stored Cognito user, so check for a Cognito session
        // console.log('[userContext] no authenticated user, attempting Cognito session...');
        cognitoUser = await createCognitoUserFromSession().catch((err) => {
          console.log(err);
          return null;
        });
      }

      // If we have a Cognito user, load the myEMS record if we don't have it
      if (cognitoUser) {
        // If we don't have a myEMS user record, load it.
        if (!getRecordRef()?.id) {
          // console.log('[userContext] got authenticated user, attempting reload...');
          await loadUserRecord(cognitoUser);
        } else {
          updateState({ userSession: cognitoUser });
        }
        // console.log('[userContext] loaded stored user');
      } else {
        // We have nothing, set nulls
        updateState(null, true);
      }
    } catch (err) {
      updateState(null, true);
      throw err;
    } finally {
      authRef.current = false;
      setAuthenticating(false);
    }
    // eslint-disable-next-line
  }, [loadUserRecord, loadingRecord]);

  const getStaffUsersWithCurrentUser = async () => {
    const staffUsers = await getStaffUsers().catch((err) => {
      console.log('[getStaffUsers] error:', err);
      return [];
    });
    const staffUser = staffUsers.find((u) => u.email === userState.userRecord.email);
    if (!staffUser) {
      const u = {
        email: userState.userRecord.email,
        firstName: userState.userRecord.first_name,
        lastName: userState.userRecord.last_name,
      };
      u.name = getFullName(u);
      staffUsers.push(u);
    }
    return staffUsers;
  };

  // Log in the guest user so inep end user can create events
  const signInGuest = async () => {
    try {
      await Auth.signIn({
        username: process.env.REACT_APP_GUEST_USER_EMAIL,
        password: process.env.REACT_APP_GUEST_USER_PASSWORD,
      });
    } catch (error) {
      console.log('Temporary sign in failed.', error);
      navigate('/login');
    }
  }

  const signOutGuest = async () => {
    try {
      await Auth.signOut();
      storage.clear();
    } catch (err) {
      console.log('Error signing out guest user...');
    }
  };

  const handleAuthCode = async (code) => {
    authRef.current = true;
    setAuthenticating(true);
    try {
      let cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true }).catch(() => null);
      if (cognitoUser) {
        // In the event this is a different user, we will replace the existing user with the freshly authenticated one
        await Auth.signOut({ global: true }).catch(() => {
          storage.clear();
        });
      }
      await getTokenByCode(code);
      cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true }).catch(() => null);
      // console.log('[UserContext][handleAuthCode] current user after getTokenByCode', cognitoUser);
      if (cognitoUser) {
        await loadUserRecord(cognitoUser);
      } else {
        console.log('[userContext][handleAuthCode] NO Cognito user after token');
        storage.clear();
        throw new Error('Unable to authenticate user session.');
      }
    } catch (err) {
      console.log('login error', err);
      storage.clear();
      throw err;
    }
    authRef.current = false;
    setAuthenticating(false);
  };

  useEffect(() => {
    pageLoadRestoreAuth().then();
    // eslint-disable-next-line
  }, []);

  return (
    <UserContext.Provider value={{
      userState,
      setUserState,
      updateUserRecord,
      loadUserRecord,
      reloadUserRecord,
      handleAuthCode,
      signIn,
      signOut,
      signInGuest,
      signOutGuest,
      isUser,
      isAdmin,
      isLoggedIn,
      isSSO,
      hasRole,
      getStaffUsersWithCurrentUser,
      loadingRecord,
      authenticating,
      setAuthenticating,
      authenticated,
    }}>
      <AbilityContext.Provider value={defaultAbility}>
        <>{ props.children }</>
      </AbilityContext.Provider>
    </UserContext.Provider>
  );
};

export default UserContext;
