import React from 'react';
import {Alert, AlertTitle} from "@mui/material";

/**
 * Renders an alert box showing errors that occurred while loading data.
 * Can be used on any page that loads data using React Query.
 *
 * @param {string} title
 * @param {object[]} errors
 */
const DataLoadingError = ({title, errors}) => (
  <Alert severity="error">
    <AlertTitle>{title}</AlertTitle>
    {errors.map(e => (<div>{e.message}</div>))}
  </Alert>
);

export default DataLoadingError;
