import React, { useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { Alert, AlertTitle, Button, IconButton, InputAdornment, Link, Typography, useTheme, } from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { AutocompleteElement, FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';

import UserContext from '../../../lib/contexts/userContext';
import { counties, states, userOptions } from '../../../lib/constants';
import Grid from '@mui/material/Unstable_Grid2';
import RadioButtonGroup from '../../common/HookFormMui/RadioButtonGroup';
import { PageContext } from '../../../lib/contexts/pageContext';
import PhoneMask from '../../common/PhoneMask';
import { scrollToAlert } from '../../../lib/utils';

const SignUp = () => {
  const { setPageMessage, setPageErrorMessage } = useContext(PageContext);
  const { isAdmin, userState: { session: user, userRecord }, isLoggedIn } = useContext(UserContext);

  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [hasError, setHasError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [completed, setCompleted] = useState(false);

  const theme = useTheme();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isSubmitting, submitCount },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: { ...userRecord }
  });

  const healthyTextQuestion = watch('program-eligibility');
  const allowEnewsletterQuestion = watch('allow_enews_question');
  const state = watch('state');

  const stateValue = watch('state', null);

  useEffect(() => {
    // When state is Illinois we switch to a dropdown list of counties.
    // If the county is not in that list, then clear it to force the user to update county.
    if (stateValue === 'IL') {
      const county = getValues('county');
      if (!counties.includes(county)) {
        setValue('county', '');
      }
    }
  }, [getValues, setValue, stateValue]);

  const [wasSubmitting, setWasSubmitting] = useState(false);
  const errorValues = Object.values(errors);

  const hasErrors = errors && Object.keys(errors).length > 0;

  useEffect(() => {
    if (hasErrors) {
      scrollToAlert();
    }
  }, [hasErrors, submitCount]);

  useEffect(() => {
    if (healthyTextQuestion === 'yes') {
      setValue('allow_text_program', null);
    }
  }, [healthyTextQuestion, setValue]);

  useEffect(() => {
    if (allowEnewsletterQuestion === 'yes') {
      setValue('allow_enews', null);
    }
  }, [allowEnewsletterQuestion, setValue]);

  useEffect(() => {
    const missingRequired = errorValues.reduce((hasRequiredError, error) => {
      if (error.type === 'required') {
        return true;
      }
      return hasRequiredError;
    }, false);

    // Here tracks if the form was submitted on the last render (meaning submit was clicked and validation is occurring)
    if (isSubmitting) {
      setWasSubmitting(true);
    } else if (wasSubmitting) {
      setWasSubmitting(false);
    }

    if (missingRequired) {
      if (wasSubmitting) {
        // Only show the alert if the form was actually submitted via the button
        setPageErrorMessage('Please fill out all required (*) fields.');
      }
    } else {
      setPageErrorMessage(null);
    }
  }, [errorValues, isSubmitting, setPageErrorMessage, wasSubmitting]);

  useEffect(() => {
    if (isLoggedIn()) {
      navigate(isAdmin() ? '/member/profile' : '/member/activities');
    }
  }, [isAdmin, isLoggedIn, navigate, user, userRecord]);

  const submit = async (data) => {
    if (!data.phone.match(/[0-9]{10}/)) {
      return false;
    }
    setHasError(false);
    setSubmitting(true);
    const phone = `+1${data.phone}`;
    const params = {
      username: data.email,
      password: data.password,
      attributes: {
        given_name: data.first_name,
        family_name: data.last_name,
        phone_number: phone,
        'custom:last_name': data.last_name,
        'custom:first_name': data.first_name,
        // 'custom:street_address': data.street_address,
        'custom:city': data.city,
        'custom:state': data.state,
        'custom:zip': data.zip,
        'custom:county': data.county,
        // 'custom:allow_cellphone': `${data.allow_cellphone}`,
        // 'custom:allow_notification': `${data.allow_notification}`,
        'custom:allow_enews': `${data.allow_enews || null}`,
        'custom:allow_text_program': `${data.allow_text_program || null}`,
        'custom:is_eligible': `${data.is_eligible || null}`,
        // 'custom:birth_year': data.birth_year,
        'custom:household_size': `${data.household_size || ''}`,
        'custom:gender': data.gender,
        'custom:ethnicity': data.ethnicity,
        'custom:race': data.race,
        'custom:age_range': data.age_range
      },
      autoSignIn: {
        enabled: true,
      }
    };
    await Auth.signUp(params)
      .then(() => {
        // console.log('sign up result', result);
        setCompleted(true);
        setPageMessage('A verification email has been sent to your email address. Please check your email and follow the instructions to verify your account.');
        document.getElementsByClassName('page-title')[0].scrollIntoView({ behavior: 'smooth' });
      })
      .catch((error) => {
        // console.log('failed signup', error.code, error.message);
        setSubmitting(false);
        setHasError(error.message);
        if (error.code === 'UsernameExistsException') {
          setError('email', { type: 'unique', message: 'Email address must be unique.' });
        }
        document.getElementsByClassName('page-title')[0].scrollIntoView({ behavior: 'smooth' });
      });
    return true;
  };

  const getOptionLabel = (dropdown) => (value) => {
    const opt = userOptions[dropdown].find((opt) => opt.value === value);
    if (opt) {
      return `${opt.label || opt.value}`;
    }
    return null;
  };

  return (
    <Grid container gap={3} wrap={'nowrap'} columns={10}>
      <Grid xs={10} sm={10} md={10} lg={10}>
        {!!hasError &&
          <Alert severity="error">
            {hasError}
          </Alert>
        }
        {!completed && (
          <FormContainer onSuccess={handleSubmit(submit)} FormProps={{ className: 'mui' }}>
            <Typography mb={'15px'} sx={{ fontWeight: 600 }} component="div">
              <label className={'form-text required'}>Required Fields</label>
              <p className={'form-text orange'}>Participant information will not be used, shared, or sold outside of
                the Eat.Move.Save. program.</p>
            </Typography>
            <Grid container spacing={2}>
              <Grid xs={12} sm={10} md={8}>
                <TextFieldElement
                  name="first_name"
                  id="field-first_name"
                  label={'First Name'}
                  variant={'standard'}
                  control={control}
                  required
                  fullWidth
                />
              </Grid>
              <Grid xs={12} sm={10} md={8}>
                <TextFieldElement
                  name="last_name"
                  id="field-last_name"
                  label={'Last Name'}
                  variant={'standard'}
                  control={control}
                  required
                  fullWidth
                />
              </Grid>
              <Grid xs={12} sm={10} md={8}>
                <TextFieldElement
                  id="field-city"
                  name="city"
                  label={'City/Town'}
                  variant={'standard'}
                  control={control}
                  required
                  fullWidth
                />
              </Grid>
              <Grid xs={12} sm={7} md={6}>
                <AutocompleteElement
                  id="field-state"
                  name="state"
                  label="State"
                  textFieldProps={{ variant: 'standard' }}
                  control={control}
                  required
                  fullWidth
                  options={states.map(item => item.abbreviation)}
                  autocompleteProps={{
                    getOptionLabel: (value) => (states.find((s) => s.abbreviation === value) || { name: null }).name
                  }}
                />
              </Grid>
              <Grid xs={12} sm={3} md={2}>
                <TextFieldElement
                  id="field-zip"
                  name="zip"
                  label={'Zip Code'}
                  variant={'standard'}
                  control={control}
                  required
                  fullWidth
                />
              </Grid>
              <Grid xs={12} sm={10} md={8}>
                {stateValue === 'IL' ? (
                  <AutocompleteElement
                    name="county"
                    label="County"
                    textFieldProps={{ variant: 'standard' }}
                    control={control}
                    required
                    fullWidth
                    options={counties}
                    autocompleteProps={{
                      id: 'field-county',
                    }}
                  />
                ) : (
                  <TextFieldElement
                    id="field-county"
                    name="county"
                    label={'County'}
                    variant={'standard'}
                    control={control}
                    required
                    fullWidth
                  />
                )}
              </Grid>
              <Grid xs={12} sm={10} md={8}>
                <TextFieldElement
                  id="field-email"
                  name="email"
                  type={'email'}
                  label={'Email'}
                  variant={'standard'}
                  control={control}
                  required
                  fullWidth
                  onChange={() => {
                    if (errors?.email?.unique) {
                      clearErrors('email');
                    }
                  }}
                />
              </Grid>
              <Grid xs={12} sm={10} md={8}>
                <TextFieldElement
                  id="field-phone"
                  name="phone"
                  type={'text'}
                  label={'Cell Phone Number'}
                  variant={'standard'}
                  control={control}
                  validation={{
                    validate: {
                      tenDigits: (value) => value.replaceAll(/[^0-9]+/g, '').length === 10 || 'Phone number must be 10 digits',
                    },
                  }}
                  InputProps={{
                    inputComponent: PhoneMask,
                  }}
                  required
                  fullWidth
                />
              </Grid>
              <Grid xs={10} id="field-is_eligible">
                <RadioButtonGroup
                  name={'is_eligible'}
                  label={'Are you eligible to participate in any of the following programs?'}
                  formLabelProps={{
                    focused: false,
                  }}
                  row={true}
                  description={(error) => (
                    <ul style={{
                      listStyleType: 'none',
                      columns: 2,
                      paddingLeft: 0,
                      margin: 0,
                      color: error ? theme.palette.error.main : '#000'
                    }}>
                      <li>SNAP/LINK</li>
                      <li>WIC</li>
                      <li>Free or reduced-price school lunch or breakfast</li>
                      <li>Free summer meals</li>
                      <li>Medicaid</li>
                      <li>Head start</li>
                      <li>Food pantries</li>
                      <li>Affordable housing or shelters</li>
                    </ul>
                  )}
                  helperTextProps={{
                    sx: {
                      marginRight: 0,
                      marginLeft: 0,
                      marginTop: 0,
                      color: '#000',
                    },
                  }}
                  options={[
                    { id: 'true', label: 'Yes' },
                    { id: 'false', label: 'No' },
                  ]}
                  control={control}
                  required
                />
              </Grid>
              <Grid xs={10} id="field-program_eligibility">
                <RadioButtonGroup
                  name={'program-eligibility'}
                  label={'Do you participate in the Eat.Move.Save. Healthy Text Program?'}
                  formLabelProps={{
                    focused: false,
                  }}
                  row={true}
                  description={() => 'In this program participants get 1-2 messages on their cell phone each week with fun, ' +
                    'healthy tips for you and your family! Messages may also include upcoming free or low-cost events and classes.'}
                  helperTextProps={{
                    sx: {
                      marginRight: 0,
                      marginLeft: 0,
                      marginTop: 0,
                      color: '#000',
                    },
                  }}
                  options={[
                    { id: 'yes', label: 'Yes' },
                    { id: 'no', label: 'No' },
                    { id: 'unsure', label: 'Unsure' },
                  ]}
                  control={control}
                />
              </Grid>
              {(healthyTextQuestion === 'no' || healthyTextQuestion === 'unsure') &&
                <Grid xs={10} id="field-text_program">
                  <RadioButtonGroup
                    name={'allow_text_program'}
                    label={
                      <>
                        <span>Would you like to sign up for the Eat.Move.Save. Healthy Text Program?</span>
                        <span style={{ color: 'green', marginLeft: '5px', fontWeight: 600 }}>25 Points</span>
                      </>
                    }
                    formLabelProps={{
                      focused: false,
                    }}
                    row={true}
                    options={[
                      { id: 'true', label: 'Yes' },
                      { id: 'false', label: 'No' },
                    ]}
                    control={control}
                  />
                </Grid>
              }
              <Grid xs={10} id="field-enews_question">
                <RadioButtonGroup
                  name={'allow_enews_question'}
                  label={'Do you receive the Eat.Move.Save. Healthy eNewsletter each month?'}
                  formLabelProps={{
                    focused: false,
                  }}
                  row={true}
                  description={() =>
                    <p>
                      This newsletter comes from the University of Illinois-Illinois Nutrition Education Programs
                      (<span
                      style={{ color: 'red' }}>eatmovesave@illinois.edu</span>)
                    </p>
                  }
                  helperTextProps={{
                    sx: {
                      marginRight: 0,
                      marginLeft: 0,
                      marginTop: 0,
                      color: '#000',
                    },
                  }}
                  options={[
                    { id: 'yes', label: 'Yes' },
                    { id: 'no', label: 'No' },
                    { id: 'unsure', label: 'Unsure' },
                  ]}
                  control={control}
                />
              </Grid>
              {(allowEnewsletterQuestion === 'no' || allowEnewsletterQuestion === 'unsure') &&
                <Grid xs={10} id="field-allow_enews">
                  <RadioButtonGroup
                    name={'allow_enews'}
                    label={
                      <>
                        <span>Would you like to sign up for the Eat.Move.Save. Healthy eNewsletter Program?</span>
                        <span style={{ color: 'green', marginLeft: '5px', fontWeight: 600 }}>10 Points</span>
                      </>
                    }
                    formLabelProps={{
                      focused: false,
                    }}
                    row={true}
                    options={[
                      { id: 'true', label: 'Yes' },
                      { id: 'false', label: 'No' },
                    ]}
                    control={control}
                  />
                </Grid>
              }
              <Grid xs={12} sm={10} md={8}>
                <AutocompleteElement
                  name="household_size"
                  label={'Household Size'}
                  textFieldProps={{ variant: 'standard' }}
                  control={control}
                  fullWidth
                  options={userOptions.householdSize.map(opt => opt.value)}
                  autocompleteProps={{
                    getOptionLabel: getOptionLabel('householdSize'),
                    id: 'field-household_size',
                  }}
                />
              </Grid>
              <Grid xs={12} sm={10} md={8}>
                <AutocompleteElement
                  id="field-gender"
                  name="gender"
                  label={'Gender'}
                  textFieldProps={{ variant: 'standard' }}
                  control={control}
                  fullWidth
                  options={userOptions.gender.map(opt => opt.value)}
                  autocompleteProps={{
                    getOptionLabel: getOptionLabel('gender'),
                    id: 'field-gender',
                  }}
                />
              </Grid>
              <Grid xs={12} sm={10} md={8}>
                <AutocompleteElement
                  id="field-ethnicity"
                  name="ethnicity"
                  label={'Ethnicity'}
                  textFieldProps={{ variant: 'standard' }}
                  control={control}
                  fullWidth
                  options={userOptions.ethnicity.map(opt => opt.value)}
                  autocompleteProps={{
                    getOptionLabel: getOptionLabel('ethnicity'),
                    id: 'field-ethnicity',
                  }}
                />
              </Grid>
              <Grid xs={12} sm={10} md={8}>
                <AutocompleteElement
                  id="field-race"
                  name="race"
                  label={'Race'}
                  textFieldProps={{ variant: 'standard' }}
                  control={control}
                  fullWidth
                  options={userOptions.race.map(opt => opt.value)}
                  autocompleteProps={{
                    getOptionLabel: getOptionLabel('race'),
                    id: 'field-race',
                  }}
                />
              </Grid>
              <Grid xs={12} sm={10} md={8}>
                <AutocompleteElement
                  id="field-age_range"
                  name="age_range"
                  label={'Age Range'}
                  textFieldProps={{ variant: 'standard' }}
                  control={control}
                  fullWidth
                  options={userOptions.ageRange.map(opt => opt.value)}
                  autocompleteProps={{
                    getOptionLabel: getOptionLabel('ageRange'),
                    id: 'field-age_range',
                  }}
                />
              </Grid>
              <Grid xs={12} sm={10} md={8} sx={{ marginBottom: '2rem' }}>
                <TextFieldElement
                  id="field-password"
                  name="password"
                  label={'Create Password'}
                  helperText={'Minimum 10 characters. You can use letters, numbers, or symbols.'}
                  variant={'standard'}
                  control={control}
                  required
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  validation={{
                    minLength: {
                      value: 10,
                      message: 'Password must have at least 10 characters'
                    }
                  }}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                      </InputAdornment>
                  }
                  }
                />
              </Grid>
              {(state !== 'IL' && state !== '') &&
                <Grid xs={12} sm={10} md={8}>
                  <Alert variant="outlined" severity="info" sx={{ marginBottom: '24px' }}>
                    <AlertTitle sx={{ color: 'ilblue.main' }}>
                      Notice for Non-Illinois Residents
                    </AlertTitle>
                    <Typography variant="body1" sx={{ color: '#000' }}>
                      myEat.Move.Save. is part of the Illinois SNAP-Education program.
                      Only Illinois residents are eligible to receive incentives from myEat.Move.Save.
                      Check with your state’s <Link target="_blank"
                                                 href="https://snaped.fns.usda.gov/state-snap-ed-programs"
                                                 rel="noreferrer">SNAP-Education
                      program</Link> for programs in your state.
                    </Typography>
                  </Alert>
                </Grid>
              }
              <Grid xs={12} sm={10} md={8}>
                <Typography>
                  After clicking SUBMIT you will receive a verification email to the address entered above.
                  Please follow the instructions in the email to activate your account.
                </Typography>
              </Grid>
              <Grid xs={12} sm={10} md={8}>
                <Button
                  disabled={submitting}
                  type={'submit'}
                  variant="contained"
                  size="small"
                  id="button-submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </FormContainer>
        )}
      </Grid>
    </Grid>
  );
};

export default SignUp;
