import React, { useCallback, useContext, useEffect, useState } from 'react';
// import '@aws-amplify/ui-react/styles.css';
import ActivitiesList from '../../../ContentComponents/Activities/ActivitiesList';
import { fetchUserEvents } from '../../../ContentComponents/Events/eventFunctions';
import { API, graphqlOperation } from 'aws-amplify';
import { scoreByUser } from '../../../../graphql/queries';
import UserContext from '../../../../lib/contexts/userContext';
import Grid from '@mui/material/Grid';
import UserEventHistory from '../../../ContentComponents/Events/UserEventHistory';
import TotalScore from '../../../ContentComponents/Member/TotalScore';
import RewardLevels from '../../../ContentComponents/Member/RewardLevels';
import { Box } from '@mui/system';

const Activities = () => {
  const [userEvents, setUserEvents] = useState([]);
  const [subTotals, setSubTotals] = useState(0);
  const [userScore, setUserScore] = useState(0);

  const { userState: { userRecord } } = useContext(UserContext);

  let userID = userRecord?.id;

  const calcSubTotals = async (userEvents) => {
    if (typeof userEvents !== 'undefined' && userEvents.length > 0) {
      let score = userEvents.reduce((points, event) => {
        if (!points[event.event_type]) {
          points[event.event_type] = event.points;
        } else {
          points[event.event_type] = points[event.event_type] + event.points;
        }
        // console.log("points: ", points)
        return points;
      }, {});
      setSubTotals(score);
    }
  };

  useEffect(() => {
    calcSubTotals(userEvents).then();
  }, [userEvents]);


  useEffect(() => {
    if (userID) {
      fetchUserEvents(userID).then((userEventsList) => setUserEvents(userEventsList));
    } else {
      setUserEvents([]);
    }
  }, [userID]);

  const updateUserScore = useCallback(() => {
    const getScoreByUser = async () => {
      try {
        const input = { userID: userID };
        const getScoreByUserResponse = await API.graphql({
          ...graphqlOperation(scoreByUser, input),
          authMode: 'API_KEY',
        });
        return (getScoreByUserResponse?.data?.scoreByUser?.items[0]?.points || '0');
      } catch (e) {
        console.log('Error getting score by user: ', e);
      }
    }
    getScoreByUser().then((score) => setUserScore(score));
  }, [userID]);

  useEffect(() => {
    if (userID) {
      updateUserScore();
    }
    // eslint-disable-next-line
  }, [updateUserScore]);

  const rewardsSidebar = (
    <Box sx={{ maxWidth: '324px' }}>
      <TotalScore userScore={userScore} />
      <RewardLevels/>
    </Box>
  );

  return (
    <Grid container spacing={2} columns={20}>
      <Grid item lg={15} xl={16}>
        <Grid container spacing={0} columns={20}>
          <Grid item order={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 1  }} xs={20} lg={20} xl={9} paddingRight={'15px'} mb={'15px'}>
            <UserEventHistory
              events={userEvents}
              subTotals={subTotals}
            />
          </Grid>
          <Grid item order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }} xs={20} lg={20} xl={11} mb={'15px'}>
            <ActivitiesList
              onEnroll={updateUserScore}
              setUserScore={setUserScore}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={5} xl={4}>
        { rewardsSidebar }
      </Grid>
    </Grid>
  );
};


// export default withAuthenticator(Login)
export default Activities;
