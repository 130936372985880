import { List, ListItem, styled, TableCell } from '@mui/material';
import TextBreaks from '../../../common/TextBreaks';
import React from 'react';
import moment from '../../../../lib/moment';
import { NILL } from '../../../../lib/constants';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ErrorIcon from "@mui/icons-material/Error";

export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      status
      eventID
      event {
        id
        event_name
        event_type
        event_partner
        internal_only
        program_areas
        curriculumID
        curriculum {
          id
          curriculum_name
          createdAt
          updatedAt
          owner
          __typename
        }
        additionalID
        additional {
          id
          additional_name
          snap_ed
          efnep
          cphp
          points
          description
          audience
          display_order
          createdAt
          updatedAt
          owner
          __typename
        }
        participant_needs
        participant_needs_other
        main_office
        special_project
        points
        created_by
        updated_by
        createdAt
        updatedAt
        __typename
      }
      lessonID
      lesson {
        id
        curriculumID
        curriculum {
          id
          curriculum_name
          createdAt
          updatedAt
          owner
          __typename
        }
        lesson_name
        booth_topic
        snap_ed
        efnep
        cphp
        points
        description
        display_order
        audience
        createdAt
        updatedAt
        owner
        __typename
      }
      start
      end
      delivery_method
      delivery_url
      delivery_language
      material_language
      delivery_location
      other_details
      has_wifi
      demonstration
      recipes
      short_code
      qr_code
      participant_count
      cancel_reason
      canceled_by
      created_by
      updated_by
      canceledAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
/**
 * Helper to get a user-friendly name for a session
 */
export const getSessionName = ({ session, showNumbers = true, dateFallback = false, useNill = true }) => {
  let name = session.session_name;
  if (!name) {
    name = session?.lesson?.lesson_name || session?.lesson?.booth_topic;
  }
  if (!name && dateFallback && session.mStart) {
    name = session.mStart.format('MMM D, YYYY | h:mm A');
  }
  if (!name) {
    if (useNill) {
      name = NILL;
    } else {
      name = session.id;
    }
  }
  if (showNumbers) {
    name = `${showNumbers ? `(${session.order} / ${session.event.counts.total}) ` : ''}${name}`;
  }
  return name;
};

export const formatSessionDates = (start, end) => {
  const mStart = moment(start);
  const mEnd = moment(end);
  const diff = mEnd.diff(mStart, 'minutes');
  if (diff > ((24*60) - 1)) {
    return `${mStart.format('MM/DD/YYYY h:mm A')} - ${mEnd.format('MM/DD/YYYY h:mm A')}`;
  }
  return `${mStart.format('MM/DD/YYYY h:mm A')} - ${mEnd.format('h:mm A')}`;
};

export const getDeliveryInfo = (session) => {
  // Sort delivery methods so In Person comes first and attach associated field data
  const methods = session.delivery_method?.sort() || [];
  let details;
  if (methods && methods.length) {
    details = methods.map((method) => {
      switch (method) {
        case 'In Person':
          return [session.delivery_location];
        case 'Virtual/Live Online':
          return [session.delivery_url, session.other_details];
        default:
          return [];
      }
    });
  } else {
    details = [session?.delivery_location, session?.delivery_url, session?.other_details].filter((item) => !!item);
  }
  return { methods, details };
};

export const getDeliveryCells = (session) => {
  const { methods, details } = getDeliveryInfo(session);

  const CellList = styled(List)({
    listStyleType: 'disc',
    padding: 0,
    paddingLeft: '18px',
    '& .MuiListItem-root': {
      display: 'list-item',
      paddingTop: 0,
      paddingBottom: 0,
    },
  });
  return (
    <>
      <TableCell align="left">
        { methods.length > 1 ? (
          <CellList>
            { methods.map((method, i) => (
              <ListItem key={`method-${i}`} disableGutters>{ method }</ListItem>
            ))}
          </CellList>
        ) : <TextBreaks texts={methods} /> }
      </TableCell>
      <TableCell align="left">
        { details.length > 1 ? (
          <CellList>
            { details.map((detail, i) => (
              <ListItem key={`method-details-${i}`} disableGutters>
                <TextBreaks texts={ detail } />
              </ListItem>
            ))}
          </CellList>
        ) : <TextBreaks texts={details} /> }
      </TableCell>
    </>
  );
};

export const getStatusIcon = (status) => {
  switch (status) {
    case 'COMPLETED':
      return <CheckCircleIcon color="success" />;
    case 'CANCELED':
      return <CancelRoundedIcon color="error" />;
    default:
      return <ErrorIcon color="yellow" />;
  }
}
