import { Pages } from '../../../../../lib/constants';
import Page from '../../../Page';
import EditSession from './EditSession';
import { useState } from 'react';

export const EditSessionPage = () => {
  const [title, setTitle] = useState('Edit Session');
  return (
    <Page
      title={title}
      breadcrumbs={['events', ':event', ':session', 'Edit Session']}
      sectionName="sessions"
      navPhoto={{ image: '/images/sessions/CREATE_EVENT_PHOTO.jpg' }}
      pageId={Pages.SESSION_EDIT}
      requireAuth
    >
      <EditSession setTitle={setTitle} />
    </Page>
  )
};

export default EditSessionPage;
