import React, { useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button, CircularProgress, Tab, Tabs } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Subjects } from '../../../../lib/permissions';
import { useNavigate } from 'react-router-dom';
import EventsTable from './EventsTable';
import useEventSessionQuery from '../../../common/hooks/useEventSessionQuery';
import DataEmptyMessage from "../../../common/DataEmptyMessage";
import DataLoadingError from "../../../common/DataLoadingError";
import OverlaySpinner from '../../../common/OverlaySpinner';

const ViewEvents = () => {
  const [currentTab, setCurrentTab] = useState('external');

  const navigate = useNavigate();

  const permissions = { subject: Subjects.EVENTS, action: 'view' };
  // sessions filter
  const secondaryQueryFilter = { status: { ne: 'CANCELED' } };

  const {
    data: events,
    loading,
    refetching,
    error,
    isError,
  } = useEventSessionQuery({
    permissions,
    secondaryQueryFilter,
    selectFn: (events) => {
      if (!events || events.length === 0) {
        return [];
      }

      events.forEach((e) => {
        e.curriculum_name = e?.curriculum?.curriculum_name || 'Booth';
      });
      return events;
    },
  });

  const { externalEvents, internalEvents } = useMemo(() => {
    if (events && events.length) {
      return {
        externalEvents: events.filter((e) => !e.internal_only),
        internalEvents: events.filter((e) => e.internal_only),
      };
    } else {
      return {
        externalEvents: [],
        internalEvents: [],
      };
    }
  }, [events]);

  // The tab list defaults to the 'community events' tab. If that one has no data,
  // show the 'training events' tab instead.
  let actualTab = currentTab;
  if (!externalEvents.length && internalEvents.length) {
    actualTab = 'internal';
  }

  return (
    <div id="events" style={{ position: 'relative' }}>
      <OverlaySpinner condition={refetching} />
      <Grid container spacing={1}>
        {loading && (
          <Grid item xs={12} textAlign="center">
            <CircularProgress />
          </Grid>
        )}
        {!loading && (
          <>
            <Grid item xs={12} textAlign="right">
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  <Add sx={{ margin: "-5px", fontSize: '24px !important' }}/>
                }
                onClick={() => {
                  navigate('/events/create');
                }}
              >
                Create Event
              </Button>
            </Grid>

            {!isError && events.length > 0 && (
              <Grid item xs={12}>
                <Tabs
                  value={actualTab}
                  onChange={(event, newValue) => setCurrentTab(newValue)}
                >
                  {externalEvents.length && (
                    <Tab label="Community Events" value="external" />
                  )}
                  {internalEvents.length && (
                    <Tab label="Training/Test Events" value="internal" />
                  )}
                </Tabs>
                <div role="tabpanel" hidden={actualTab !== 'external'}>
                  <EventsTable events={externalEvents} />
                </div>
                <div role="tabpanel" hidden={actualTab !== 'internal'}>
                  <EventsTable events={internalEvents} />
                </div>
              </Grid>
            )}

            {!isError && !events.length && (
              <DataEmptyMessage title={"You have no events."} />
            )}
          </>
        )}

        {isError && (
            <DataLoadingError title={"Failed to load events!"} errors={error.errors} />
        )}
      </Grid>
    </div>
  );
};

export default ViewEvents;
