const TextBreaks = ({ texts }) => {
  if (Array.isArray(texts)) {
    return texts.filter((txt) => txt).map((txt, i) => (
      <div style={{ whiteSpace: 'pre-line' }} key={`text-${i}`}>
        {txt}
      </div>
    ));
  }

  return texts;
};

export default TextBreaks;
