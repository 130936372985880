import { useLocation, Navigate } from 'react-router-dom';
import UserContext from '../../lib/contexts/userContext';
import { useContext } from 'react';

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { isLoggedIn, authenticating } = useContext(UserContext);

  if (!authenticating && !isLoggedIn(false)) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

export default RequireAuth;
