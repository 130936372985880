import React from 'react';
import {Typography, useTheme} from "@mui/material";

/**
 * Renders an alert box showing errors that occurred while loading data.
 * Can be used on any page that loads data using React Query.
 *
 * @param {string} title
 */
const DataEmptyMessage = ({title}) => {
  const theme = useTheme();

  return (
    <Typography
        variant="h6"
        fontSize={theme.typography.pxToRem(16)}
        fontWeight="500"
        sx={{marginTop: '32px', width: '100%', textAlign: 'center'}}
    >
      {title}
    </Typography>
  );
};

export default DataEmptyMessage;
