import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { PageContext } from '../../../../lib/contexts/pageContext';
import {
  Button,
  Chip, CircularProgress,
  Link,
  List,
  ListItem,
  Paper,
  Tab,
  TableCell,
  TableContainer,
  Tabs,
  Typography,
  useTheme
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import moment from '../../../../lib/moment';
import { useAbility } from '@casl/react';
import { NILL, participantNeeds, programAreas } from '../../../../lib/constants';
import { graphQuery } from '../../../../lib/api';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { AbilityContext, Subjects } from '../../../../lib/permissions';
import MuiTable from '../../../common/MuiTable';
import { subject } from '@casl/ability';
import { deleteEvent, deleteSession } from '../../../../graphql/mutations';
import { defaultComparator } from '../../../../lib/utils';
import { useConfirm } from '../../../common/hooks/useConfirm';
import { formatSessionDates, getDeliveryCells, getStatusIcon } from '../Sessions/sessionFunctions';
import SessionStatusLegend from '../Sessions/SessionStatusLegend';
import useEventSessionQuery from '../../../common/hooks/useEventSessionQuery';
import Grid from '@mui/material/Grid';

const EventDetails = ({ setTitle }) => {
  const [deleting, setDeleting] = useState(false);

  const {
    setPageErrorMessage,
    setBreadcrumbValue,
  } = useContext(PageContext);

  const { eventID } = useParams();

  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);
  const confirm = useConfirm();
  const theme = useTheme();

  const {
    data: event,
    loading,
    error,
    isError,
  } = useEventSessionQuery({
    permissions: {
      action: 'view',
      subject: Subjects.EVENT_DETAILS,
      id: eventID,
    },
    selectFn: (events) => {
      if (!events || events.length === 0) {
        return null;
      }
      return events[0];
    },
  });

  useEffect(() => {
    if (isError) {
      console.log('[EventDetails] error fetching session:', error);
      setTitle('Error Accessing Page');
    }
  }, [error, isError, setTitle]);

  useEffect(() => {
    if (event) {
      setBreadcrumbValue(':event', event);
    }
    // eslint-disable-next-line
  }, [event]);

  const handleDelete = async () => {

    if (ability.cannot('delete', subject(Subjects.EVENT, event ))) {
      setPageErrorMessage('You do not have permission to delete this event.');
      return false;
    }

    const confirmDelete = await confirm({
      title: 'Are you sure you want to delete this event?',
      description: 'If you delete this event, all its sessions will also be deleted. This cannot be undone.',
      confirmationText: 'DELETE EVENT',
      cancellationText: 'KEEP EVENT',
    })
      .then(() => true)
      .catch(() => false);

    if (!confirmDelete) {
      return;
    }
    // TODO: Check to see if any sessions have participants first, if they do, abort.

    setDeleting(true);
    let deleted = false;
    try {
      await Promise.all(event.sessions.items.map(({ id }) =>
        graphQuery(deleteSession, { input: { id } })));
      const result = await graphQuery(deleteEvent, { input: { id: eventID } });

      if (result.data.deleteEvent?.id) {
        deleted = true;
      }
    } catch (err) {
      console.log('Error: ', err);
    }
    if (deleted) {
      navigate('/events', { state: { message: 'The event and its sessions have been deleted.' } });
    } else {
      setPageErrorMessage('An error occurred while deleting event. Please contact <support email address> for assistance.');
      setDeleting(false);
    }
  };

  const getProgramAreaLabel = (area) => {
    const opt = programAreas.find((pa) => pa.id === area);
    return opt?.label;
  };

  const programAreaLabels = (areas) => areas.reduce((acc, area) => {
    const label = getProgramAreaLabel(area);
    if (label) {
      acc.push(label);
    }
    return acc;
  }, []).join(', ');

  const headCells = [
    {
      id: 'date',
      label: 'Date & Time',
    },
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'session_name',
      label: 'Session Name',
    },
    {
      id: 'delivery_method',
      label: 'Delivery Method',
    },
    {
      id: 'delivery_site',
      label: 'Delivery Site & Address',
    },
  ];

  /**
   * Returns a comparison function.
   *
   * @param order
   * @param orderBy
   */
  const getComparator = (order, orderBy) => {
    const ord = order === 'desc' ? -1 : 1;
    switch (orderBy) {
      case 'date':
        return (a, b) => {
          const aStart = a?.start ? moment(a.start) : null;
          const bStart = b?.start ? moment(b.start) : null;
          if (a === b) return 0;
          if (!aStart) return -1 * ord;
          if (!bStart) return 1 * ord;
          return aStart.diff(bStart) * ord;
        };
      case 'session_name':
        return (a, b) => a.session_name.localeCompare(b.session_name) * ord;
      case 'delivery_method':
      case 'delivery_site':
        const getText = (e) => [e.delivery_location, e.delivery_url, e.other_details].join();
        return (a, b) => getText(a).localeCompare(getText(b)) * ord;
      case 'status':
        const nonNull = (e) => e[orderBy] || NILL;
        return (a, b) => {
          return nonNull(a).localeCompare(nonNull(b)) * ord;
        };
      default:
        return defaultComparator;
    }
  };

  const getParticipantNeeds = () => event.participant_needs.map((id) => {
    if (id === 'other') {
      return `Other: ${event.participant_needs_other}`;
    }
    const need = participantNeeds.find((pn) => pn.id === id);
    return need?.label || id;
  });

  return (
    <div id="pagewrapper" className={'with-two-sidebar'} style={{ padding: 0 }}>
      <div id="content-wrapper" className={'event-details-card'}>
        { loading && (
          <Grid item xs={12} textAlign="center">
            <CircularProgress />
          </Grid>
        )}
        {!loading && event && (
          <>
            <Paper elevation={3}>
              <Box padding={3}>
                <Typography variant="h4" color="ilstorm.main">
                  {event.event_name}
                </Typography>
                { event.internal_only && (
                  <Chip label="Training/Test Event" />
                )}
                <List dense={true}>
                  <ListItem disablePadding>
                    Program Area(s): {programAreaLabels(event.program_areas)}
                  </ListItem>
                  { event.curriculum && (
                    <ListItem disablePadding>
                      Primary Curriculum: {event?.curriculum?.curriculum_name}
                    </ListItem>
                  )}
                  { event.curriculum?.additional?.items?.length > 0 && (
                    <ListItem disablePadding>
                      Additional Curriculum: {event?.additional?.additional_name || 'None'}
                    </ListItem>
                  )}
                  <ListItem disablePadding>
                    Partner Site: {event.event_partner}
                  </ListItem>
                  { event.participant_needs?.length > 0 && (
                    <ListItem disablePadding>
                      Specific Needs: {getParticipantNeeds().join(', ')}
                    </ListItem>
                  )}
                  { event?.special_project && (
                    <ListItem disablePadding>
                      Special Project: {event.special_project}
                    </ListItem>
                  )}
                  <ListItem disablePadding>
                    Created By: {event.created_by_names}
                  </ListItem>
                  <ListItem disablePadding>
                    Last Modified: {`${event.updated_by_names}, ${moment(event.updatedAt).format('MM/D/y h:mm A')}`}
                  </ListItem>
                </List>
                <Stack direction="row" justifyContent="space-between" sx={{ marginTop: '20px' }}>
                  <Button
                    variant="filled"
                    startIcon={<EditIcon />}
                    component={RouterLink}
                    to={`/events/edit/${eventID}`}
                  >
                    Edit Event
                  </Button>
                  <Button
                    variant="filled"
                    color="error"
                    startIcon={<ClearIcon />}
                    disabled={deleting}
                    onClick={handleDelete}
                  >
                    Delete Event
                  </Button>
                </Stack>
              </Box>
            </Paper>
            <Tabs
              value={0}
              textColor="primary"
              indicatorColor="primary"
              sx={{
                marginTop: '32px',
                '.MuiButtonBase-root': {
                  fontSize: '18px'
                }
              }}
            >
              <Tab label="SESSIONS" value={0} />
            </Tabs>
            <Box mt="28px">
              <Button
                variant="filled"
                startIcon={<AddIcon />}
                component={RouterLink}
                to={`/events/${eventID}/sessions/add`}
              >
                Add Session
              </Button>
              {event.sessions.items.length > 0 && (
                <>
                  <SessionStatusLegend/>
                  <TableContainer sx={{marginTop: '32px'}}>
                    <MuiTable
                        defaultOrderBy="date"
                        headCells={headCells}
                        data={event.sessions.items}
                        getComparator={getComparator}
                        alignTop
                    >
                      {(row) => (
                        <>
                          <TableCell
                              component="th"
                              scope="row"
                          >
                            <Link to={`/events/${row.eventID}/${row.id}/`} component={RouterLink}>
                              {formatSessionDates(row.start, row.end)}
                            </Link>
                          </TableCell>
                          <TableCell align="left">{getStatusIcon(row.status)}</TableCell>
                          <TableCell
                              align="left">{row.session_name || NILL}</TableCell>
                          {getDeliveryCells(row)}
                        </>
                      )}
                    </MuiTable>
                  </TableContainer>
                </>
              )}
              { event.sessions.items.length < 1 && (
                <Typography
                  variant="h6"
                  fontSize={theme.typography.pxToRem(16)}
                  fontWeight="500"
                  sx={{ marginTop: '32px', width: '100%', textAlign: 'center' }}
                >
                  There are no sessions for this event.
                </Typography>
              )}
            </Box>
          </>
        )}
        { (!loading && event === false) && (
          <>
            You do not have access, or the event was not found.
          </>
        )}
      </div>
    </div>
  );
};

export default EventDetails;
