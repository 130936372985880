import React, { useContext, useEffect, useState } from 'react';
import { useAbility } from '@casl/react';
import { PageContext } from '../../../../lib/contexts/pageContext';
import Grid from '@mui/material/Unstable_Grid2';
import { useForm } from 'react-hook-form';
import { API, graphqlOperation } from 'aws-amplify';
import { createEvent } from '../../../../graphql/mutations';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import { scrollToAlert } from '../../../../lib/utils';
import { getCurricula, getPearsData } from '../eventFunctions';
import { AbilityContext, Subjects } from '../../../../lib/permissions';
import UserContext from '../../../../lib/contexts/userContext';
import EventForm from '../EventForm';
import { useNavigate } from 'react-router-dom';
import useInvalidateQuery from '../../../common/hooks/useInvalidateQuery';

const CreateEvent = () => {
  const {
    setPageMessage,
    setPageErrorMessage,
  } = useContext(PageContext);
  const { userState: { userRecord }, getStaffUsersWithCurrentUser } = useContext(UserContext);

  const ability = useAbility(AbilityContext);

  const navigate = useNavigate();

  const invalidateQueries = useInvalidateQuery();

  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState(null);
  const valuesSetState = useState(null);
  // const [, setValuesSet] = valuesSetState;

  const form = useForm({
    mode: 'onSubmit',
    shouldFocusError: false,
    defaultValues: {
      point: null,
      event_type: null,
      additional: null,
      curriculum: null,
      participant_needs: null,
      program_areas: null,
      created_by: userRecord?.email,
      event_name: null,
      event_partner: null,
      main_office: null,
      special_project: null,
      internal_only: null,
      description: 'Event description coming in a future phase.',
    }
  });

  /** Pull curricula and staff users on load **/
  useEffect(() => {
    Promise.all([
      getCurricula(),
      getStaffUsersWithCurrentUser(),
      getPearsData(),
    ]).then(([curricula, staff, pears]) => {
      setFormData({
        curricula: curricula,
        staff,
        pears,
      });
    });
    // eslint-disable-next-line
  }, []);

  /**
   * Submit handler.
   *
   * @param values
   */
  const onSubmit = async (values) => {
    setPageMessage(null);
    setPageErrorMessage(null);
    setSubmitting(true);

    const input = Object.keys(values).reduce((acc, key) => {
      const value = values[key];
      switch (key) {
        case 'additional':
        case 'curriculum':
          if (value?.id) {
            acc[`${key}ID`] = value?.id;
          }
          break;
        // case 'description':
        //   acc[key] = value || 'Event description coming in a future phase.';
        //   break;
        case 'participant_needs':
        case 'program_areas':
        case 'created_by':
        case 'event_name':
        case 'event_partner':
        case 'event_type':
        case 'main_office':
        case 'points':
        case 'special_project':
          acc[key] = value?.id || value;
          break;
        case 'participant_needs_other':
          if (values.participant_needs.includes('other')) {
            acc[key] = value?.id || value;
          } else {
            acc[key] = null;
          }
          break;
        case 'internal_only':
          acc[key] = values[key] === 'true';
          break;
        default: break;
      }
      return acc;
    }, {
      updated_by: userRecord.email,
    });

    if (ability.cannot('create', Subjects.EVENT)) {
      setSubmitting(false);
      setPageErrorMessage('You do not have permission to create events.');
      scrollToAlert();
      return false;
    }
    // Staff users can only assign events to themselves
    if (input.created_by !== userRecord?.email && ability.cannot('assign', Subjects.EVENT)) {
      setSubmitting(false);
      setPageErrorMessage('You do not have permission to assign events to other staff members.');
      scrollToAlert();
      return false;
    }
    try {
      const result = await API.graphql({
        ...graphqlOperation(createEvent, { input }),
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      await invalidateQueries('listEvents');
      const id = result.data.createEvent.id;
      setSubmitting(false);
      navigate(`/events/${id}`, { state: { message: 'Event created' }});
    } catch (error) {
      console.log('Error creating event: ', error);
      setSubmitting(false);
      setPageErrorMessage('Error creating event. Please try again.');
      scrollToAlert();
    }
  };

  return (
    <Grid container gap={3} wrap={'nowrap'} columns={10}>
      <Grid xs={10}>
        { formData === false && (
          <>You do not have access, or the event was not found.</>
        )}
        { formData === null && (
          <Box width="100%" textAlign="center">
            <CircularProgress />
          </Box>
        )}
        { !!formData && (
          <EventForm
            form={form}
            event={false}
            pearsData={formData.pears}
            staffUsers={formData.staff}
            availableCurriculum={formData.curricula}
            submitting={submitting}
            onSubmit={onSubmit}
            valuesSetState={valuesSetState}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CreateEvent;
